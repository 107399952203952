define("site/controllers/groups/show/permissions", ["exports", "ember-ric-utils/mixins/search-term", "ember-ric-utils/mixins/filtered-records"], function (_exports, _searchTerm, _filteredRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_searchTerm.default, _filteredRecords.default, {
    _permissions: Ember.computed.alias('model.permissions'),
    permissions: Ember.computed.filterBy('_permissions', 'isDeleted', false),
    sortedRecords: Ember.computed.alias('permissions'),
    columns: [{
      label: 'R',
      sortable: false,
      valueProperty: 'r',
      class: 'checkbox-indicator'
    }, {
      label: 'C',
      sortable: false,
      valueProperty: 'c',
      class: 'checkbox-indicator'
    }, {
      label: 'U',
      sortable: false,
      valueProperty: 'u',
      class: 'checkbox-indicator'
    }, {
      label: 'D',
      sortable: false,
      valueProperty: 'd',
      class: 'checkbox-indicator'
    }, {
      label: 'S',
      sortable: false,
      valueProperty: 's',
      class: 'checkbox-indicator'
    }, {
      label: 'A',
      sortable: false,
      valueProperty: 'A',
      class: 'checkbox-indicator'
    }, {
      label: 'Name',
      sortable: false,
      valueProperty: 'name'
    }, {
      label: 'URL',
      sortable: false,
      valueProperty: 'url'
    }, {
      label: '',
      class: 'hover-action'
    }]
  });

  _exports.default = _default;
});