define('ember-contextual-table/components/data-sorter', ['exports', 'ember-contextual-table/templates/data-sorter'], function (exports, _dataSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  var Component = Ember.Component;
  var isEmpty = Ember.isEmpty;
  var get = Ember.get;
  var computed = Ember.computed;


  function internalSorter(data, sortFields) {

    if (isEmpty(sortFields)) {
      return data;
    }

    var internalComparator = new InternalComparator(sortFields);
    data.sort(internalComparator.compare);

    return data;
  }

  function InternalComparator(sortFields) {
    this.compare = function (a, b) {
      for (var index = 0; index < sortFields.length; index++) {
        var sortField = sortFields[index];
        var coefficient = sortField['isAscending'] ? 1 : -1;
        var fieldName = sortField['fieldName'];
        var result = simpleCompare(get(a, fieldName), get(b, fieldName));

        if (result !== 0) {
          return result * coefficient;
        }
      }

      return 0;
    };
  }
  function simpleCompare(a, b) {
    var comparison = a > b;
    var equality = a === b;

    return equality ? 0 : comparison ? 1 : -1;
  }

  function createSortField(fieldName) {
    var isAscending = true;
    var tokens = fieldName.split(':');
    if (tokens.length > 0) {
      isAscending = tokens[1] !== 'desc';
    }

    return { fieldName: tokens[0], isAscending: isAscending };
  }

  exports.default = Component.extend({
    layout: _dataSorter.default,
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      if (isEmpty(this.get('sortFields'))) {
        this.set('sortFields', A());
      }
    },


    sortedData: computed('data', 'sortFields.[]', 'sorter', function () {
      if (isEmpty(this.get('data'))) {
        return [];
      }

      var sorter = this.get('sorter') || internalSorter;

      return sorter(this.get('data').slice(), this.get('internalSortFields'));
    }),

    internalSortFields: computed('sortFields.[]', function () {
      var result = A();

      if (isEmpty(this.get('sortFields'))) {
        return result;
      }

      this.get('sortFields').forEach(function (sortField) {
        result.pushObject(createSortField(sortField));
      });

      return result;
    }),

    actions: {
      onsortfieldupdated: function onsortfieldupdated(fieldName, isAscending) {
        var filteredArray = this.get('sortFields').filter(function (item) {
          return item.indexOf(fieldName) !== 0;
        });

        if (!isEmpty(isAscending)) {
          var postFix = isAscending ? "asc" : "desc";
          filteredArray.push(fieldName + ':' + postFix);
        }

        this.set('sortFields', filteredArray);
      }
    }
  });
});