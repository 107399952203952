define("ember-bootstrap/templates/components/bs-dropdown/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MIRu/EKZ",
    "block": "{\"symbols\":[\"@dropdownElementId\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"_isOpen\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ember-popper\",[[13,3]],[[\"@id\",\"@class\",\"@placement\",\"@popperTarget\",\"@renderInPlace\",\"@popperContainer\",\"@modifiers\"],[[29,[[23,1,[]],\"__menu\"]],[29,[\"dropdown-menu \",[23,0,[\"alignClass\"]],\" \",[28,\"if\",[[23,0,[\"isOpen\"]],\"show\"],null],\" \",[23,2,[]]]],[23,0,[\"popperPlacement\"]],[23,0,[\"toggleElement\"]],[23,0,[\"_renderInPlace\"]],[23,0,[\"destinationElement\"]],[23,0,[\"popperModifiers\"]]]],{\"statements\":[[0,\"\\n    \"],[14,4,[[28,\"hash\",null,[[\"item\",\"link-to\",\"divider\"],[[28,\"component\",[[23,0,[\"itemComponent\"]]],null],[28,\"component\",[[23,0,[\"linkToComponent\"]]],null],[28,\"component\",[[23,0,[\"dividerComponent\"]]],null]]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu.hbs"
    }
  });

  _exports.default = _default;
});