define('ember-ric-bootstrap/components/ric-fieldset/component', ['exports', 'ember-ric-bootstrap/components/ric-fieldset/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'fieldset',
    classNames: ['col-12', 'col-xs-12'],
    classNameBindings: ['fullScreen::col-md-8'],

    legend: null,
    fullScreen: true
  });
});