define("ember-ric-bootstrap/components/ric-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7VNCXdti",
    "block": "{\"symbols\":[\"form\",\"&default\"],\"statements\":[[4,\"bs-form\",null,[[\"model\",\"onSubmit\",\"class\"],[[24,[\"model\"]],[24,[\"onSubmit\"]],[24,[\"class\"]]]],{\"statements\":[[0,\"  \"],[14,2,[[28,\"hash\",null,[[\"form\",\"model\",\"input\",\"textarea\",\"checkbox\",\"select\",\"fieldset\",\"actions\"],[[23,1,[]],[24,[\"model\"]],[28,\"component\",[[23,1,[\"element\"]]],[[\"class\"],[\"col-12 col-xs-12\"]]],[28,\"component\",[[23,1,[\"element\"]]],[[\"controlType\",\"class\"],[\"textarea\",\"col-12 col-xs-12\"]]],[28,\"component\",[[23,1,[\"element\"]]],[[\"controlType\",\"class\"],[\"checkbox\",\"col-12 col-xs-12\"]]],[28,\"component\",[\"ric-select\"],null],[28,\"component\",[\"ric-fieldset\"],[[\"fullScreen\"],[[24,[\"fullScreen\"]]]]],[28,\"component\",[\"ric-form-actions\"],[[\"fullScreen\",\"model\",\"onCancel\"],[[24,[\"fullScreen\"]],[24,[\"model\"]],[24,[\"onCancel\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-bootstrap/components/ric-form/template.hbs"
    }
  });

  _exports.default = _default;
});