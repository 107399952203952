define("ember-bootstrap/templates/components/bs-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TSTuKdC2",
    "block": "{\"symbols\":[\"@model\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"element\",\"group\",\"isSubmitting\",\"isSubmitted\",\"isRejected\",\"resetSubmissionState\",\"submit\"],[[28,\"component\",[[23,0,[\"elementComponent\"]]],[[\"model\",\"formLayout\",\"horizontalLabelGridClass\",\"showAllValidations\",\"disabled\",\"readonly\",\"onChange\",\"_onChange\"],[[23,1,[]],[23,0,[\"formLayout\"]],[23,0,[\"horizontalLabelGridClass\"]],[23,0,[\"showAllValidations\"]],[23,0,[\"disabled\"]],[23,0,[\"readonly\"]],[28,\"action\",[[23,0,[]],\"change\"],null],[28,\"action\",[[23,0,[]],\"resetSubmissionState\"],null]]]],[28,\"component\",[[23,0,[\"groupComponent\"]]],[[\"formLayout\"],[[23,0,[\"formLayout\"]]]]],[23,0,[\"isSubmitting\"]],[23,0,[\"isSubmitted\"]],[23,0,[\"isRejected\"]],[28,\"action\",[[23,0,[]],\"resetSubmissionState\"],null],[28,\"action\",[[23,0,[]],\"submit\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form.hbs"
    }
  });

  _exports.default = _default;
});