define("site/routes/groups/show/permissions", ["exports", "site/routes/authorized", "ember-ric-utils/mixins/filtered-records"], function (_exports, _authorized, _filteredRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend(_filteredRecords.default, {
    _routeKey: 'permissions',
    model: function model() {
      var group = this.modelFor('groups.show');
      var permissions = group.get('permissions');
      return Ember.RSVP.hash({
        group: group,
        permissions: permissions
      });
    }
  });

  _exports.default = _default;
});