define("site/controllers/users/index", ["exports", "ember-ric-utils/mixins/load-data", "ember-ric-utils/mixins/search-term"], function (_exports, _loadData, _searchTerm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_STATUS_OPTIONS = ['Internal', 'External'];

  var _default = Ember.Controller.extend(_loadData.default, _searchTerm.default, {
    settings: Ember.inject.service(),
    queryParams: ['status'],
    status: null,
    init: function init() {
      this._super.apply(this, arguments);

      var firstStatus = Ember.get(this, 'settings.siteUsers.statusOptions.firstObject') || DEFAULT_STATUS_OPTIONS[0];
      this.status = this.status || firstStatus;
    },
    editDisabled: Ember.computed.alias('settings.siteUsers.editDisabled'),
    statusOptions: Ember.computed('settings.siteUsers.statusOptions', function () {
      var statusOptions = Ember.get(this, 'settings.siteUsers.statusOptions');
      return statusOptions || DEFAULT_STATUS_OPTIONS;
    })
  });

  _exports.default = _default;
});