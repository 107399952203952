define('ember-contextual-table/components/dt-selection-column-header', ['exports', 'ember-contextual-table/templates/dt-selection-column-header'], function (exports, _dtSelectionColumnHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _dtSelectionColumnHeader.default,
    tagName: 'th',
    classNames: ['contextual-selection-header-cell'],

    actions: {
      change: function change(checked) {
        var actionName = checked ? 'selected' : 'deselected';
        var action = this.get(actionName) || function () {};
        action();
      }
    }
  });
});