define("site/components/site-section-form", ["exports", "ember-ric-utils/mixins/form-base"], function (_exports, _formBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formBase.default, {
    actions: {
      updateToggle: function updateToggle(property, object) {
        Ember.set(this, "changeset.".concat(property), object.newValue === true);
      }
    }
  });

  _exports.default = _default;
});