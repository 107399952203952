define('ember-ric-utils/mixins/form-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    tagName: '',

    changeset: null,
    onSubmit: null,
    onCancel: null,
    onClose: Ember.computed.oneWay('onCancel')
  });
});