define("site/components/yt-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var pageSizes = [15, 30, 45, 60];

  var _default = Ember.Component.extend({
    tagName: 'tfoot',
    totalColumns: null,
    paginationData: null,
    paginationActions: null,
    disabled: false,
    shouldDisablePrevious: Ember.computed.or('paginationData.isFirstPage', 'disabled'),
    shouldDisableNext: Ember.computed.or('paginationData.isLastPage', 'disabled'),
    pageSizes: pageSizes,
    showInfo: true,
    showPageSizeSelector: true,
    showButtons: true
  });

  _exports.default = _default;
});