define("ember-contextual-table/templates/dt-filterable-column-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gc6RPoVB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"if\",[[25,1],[28,\"if\",[[24,[\"defaultHeader\"]],false,true],null],false],null]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"onfilterupdate\",\"footer\",\"header\"],[[28,\"action\",[[23,0,[]],\"onfilterupdate\"],null],[28,\"readonly\",[[24,[\"footer\"]]],null],[28,\"readonly\",[[24,[\"header\"]]],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"value\",\"placeholder\"],[[24,[\"filterValue\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-contextual-table/templates/dt-filterable-column-base.hbs"
    }
  });

  _exports.default = _default;
});