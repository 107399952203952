define("site/templates/users/index/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EePazB6r",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[4,\"with\",[[28,\"changeset\",[[24,[\"model\",\"user\"]],[24,[\"UpdateSiteUserValidation\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"site-user-form\",null,[[\"changeset\",\"groups\",\"onSubmit\",\"onCancel\"],[[23,1,[]],[24,[\"model\",\"groups\"]],[28,\"route-action\",[\"update\"],null],[28,\"route-action\",[\"cancel\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "site/templates/users/index/edit.hbs"
    }
  });

  _exports.default = _default;
});