define("site/templates/groups/index/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SRtPFYwC",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ric-delete-dialog\",null,[[\"model\",\"subject\",\"onSubmit\",\"onCancel\"],[[24,[\"model\"]],\"Group\",[28,\"route-action\",[\"delete\"],null],[28,\"route-action\",[\"cancel\",[24,[\"model\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "site/templates/groups/index/delete.hbs"
    }
  });

  _exports.default = _default;
});