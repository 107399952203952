define("ember-bootstrap/templates/components/bs-nav/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WZfy8EOc",
    "block": "{\"symbols\":[\"&default\",\"@route\",\"@query\",\"@models\",\"@model\"],\"statements\":[[4,\"if\",[[23,0,[\"hasLink\"]]],null,{\"statements\":[[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",[23,0,[\"linkClass\"]]]],[[\"@route\",\"@model\",\"@query\",\"@disabled\"],[[23,2,[]],[23,5,[]],[23,3,[]],[23,0,[\"disabled\"]]]],{\"statements\":[[0,\"\\n      \"],[14,1],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",[23,0,[\"linkClass\"]]]],[[\"@route\",\"@models\",\"@query\",\"@disabled\"],[[23,2,[]],[23,4,[]],[23,3,[]],[23,0,[\"disabled\"]]]],{\"statements\":[[0,\"\\n      \"],[14,1],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",[23,0,[\"linkClass\"]]]],[[\"@route\",\"@query\",\"@disabled\"],[[23,2,[]],[23,3,[]],[23,0,[\"disabled\"]]]],{\"statements\":[[0,\"\\n      \"],[14,1],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-nav/item.hbs"
    }
  });

  _exports.default = _default;
});