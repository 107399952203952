define("ember-bootstrap/mixins/control-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for BS4 validation styles added to form controls
   *
   * @class ControlValidation
   * @namespace Mixins
   * @private
   */
  var _default = Ember.Mixin.create({
    classNameBindings: ['formFeedbackClass'],
    validationType: null,
    formFeedbackClass: Ember.computed('validationType', function () {
      var validationType = this.get('validationType');

      switch (validationType) {
        case 'error':
          return 'is-invalid';

        case 'success':
          return 'is-valid';

        case 'warning':
          return 'is-warning';
        // not officially supported in BS4 :(
      }
    })
  });

  _exports.default = _default;
});