define("ember-preferences/storage/memory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    db: Ember.computed(function () {
      return Ember.Object.create();
    }),
    setItem: function setItem(key, value) {
      return this.get('db').set(key, value);
    },
    getItem: function getItem(key) {
      return this.get('db').get(key);
    },
    clear: function clear() {
      this.set('db', Ember.Object.create());
    },
    removeItem: function removeItem(key) {
      this.get('db').set(key, undefined);
    }
  });

  _exports.default = _default;
});