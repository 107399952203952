define('ember-contextual-table/components/data-table', ['exports', 'ember-contextual-table/templates/data-table'], function (exports, _dataTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  var isArray = Ember.isArray;
  var computed = Ember.computed;
  var _set = Ember.set;
  var equal = Ember.computed.equal;
  var setDiff = Ember.computed.setDiff;
  var empty = Ember.computed.empty;
  var map = Ember.computed.map;
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _dataTable.default,
    tagName: 'table',

    showHeader: true,
    showFooter: false,

    singleSelection: equal('selectionMode', 'single'),

    notSelectedRows: setDiff('data', 'selectedRows'),
    isAllSelected: empty('notSelectedRows'),

    selectionChanged: function selectionChanged() {},
    classNames: ['contextual-data-table'],

    rowIdPrefix: 'table-row-id',

    wrappedData: map('data', function (item) {
      return { row: item, isSelected: false };
    }),

    selectedRows: computed('wrappedData.@each.isSelected', {
      get: function get() {
        return this.get('wrappedData').filter(function (item) {
          return item.isSelected;
        }).map(function (item) {
          return item.row;
        });
      },
      set: function set(key, value) {
        var _this = this;

        var arr = A();
        if (isArray(value)) {
          arr.pushObjects(value);
        }

        this.get('wrappedData').forEach(function (item) {
          return _set(item, 'isSelected', false);
        });

        arr.forEach(function (item) {
          var found = _this.get('wrappedData').find(function (element) {
            return element.row === item;
          });

          if (found) {
            _set(found, 'isSelected', true);
          }
        });

        return arr;
      }
    }),

    actions: {
      selected: function selected(row) {
        if (this.get('selectionMode') === 'single') {
          this.get('wrappedData').forEach(function (item) {
            return _set(item, 'isSelected', false);
          });
        }

        _set(row, 'isSelected', true);
        this.get('selectionChanged')(this.get('selectedRows'));
      },
      deselected: function deselected(row) {
        _set(row, 'isSelected', false);
        this.get('selectionChanged')(this.get('selectedRows'));
      },
      selectAll: function selectAll() {
        if (this.get('selectionMode') === 'single') {
          return;
        }
        this.get('wrappedData').forEach(function (item) {
          return _set(item, 'isSelected', true);
        });
        this.get('selectionChanged')(this.get('selectedRows'));
      },
      deselectAll: function deselectAll() {
        this.get('wrappedData').forEach(function (item) {
          return _set(item, 'isSelected', false);
        });
        this.get('selectionChanged')(this.get('selectedRows'));
      }
    }
  });
});