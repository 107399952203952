define("site/templates/groups/show/members/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QA/zyN9N",
    "block": "{\"symbols\":[\"changeset\",\"f\",\"user\"],\"statements\":[[4,\"with\",[[28,\"changeset\",[[24,[\"model\",\"member\"]],[24,[\"CreateSiteGroupMembershipValidation\"]]],null]],null,{\"statements\":[[4,\"ric-dialog\",null,[[\"onClose\",\"class\"],[[28,\"route-action\",[\"cancel\",[23,1,[]]],null],\"site-members--new\"]],{\"statements\":[[4,\"ric-form\",null,[[\"model\",\"fullScreen\",\"onSubmit\",\"onCancel\"],[[23,1,[]],true,[28,\"route-action\",[\"create\"],null],[28,\"route-action\",[\"cancel\",[23,1,[]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"fieldset\"]],\"expected `f.fieldset` to be a contextual component but found a string. Did you mean `(component f.fieldset)`? ('site/templates/groups/show/members/new.hbs' @ L17:C9) \"],null]],null,{\"statements\":[[0,\"        \"],[7,\"legend\",true],[8],[0,\"Member\"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"form-group col-12 col-xs-12\"],[8],[0,\"\\n            \"],[7,\"label\",true],[8],[0,\"User\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"searchField\",\"onchange\"],[[28,\"sort-by\",[\"name\",[24,[\"model\",\"users\"]]],null],[23,1,[\"user\"]],\"name\",[28,\"action\",[[23,0,[]],\"updateUser\",[23,1,[]]],null]]],{\"statements\":[[0,\"              \"],[1,[23,3,[\"name\"]],false],[0,\"\\n              \"],[7,\"em\",true],[8],[1,[23,3,[\"email\"]],false],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[1,[23,2,[\"actions\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "site/templates/groups/show/members/new.hbs"
    }
  });

  _exports.default = _default;
});