define("ember-ric-bootstrap/components/ric-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4uUsEmKN",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"power-select\",null,[[\"disabled\",\"options\",\"search\",\"searchField\",\"placeholder\",\"selected\",\"loadingMessage\",\"renderInPlace\",\"onchange\",\"onblur\",\"onclose\"],[[24,[\"disabled\"]],[24,[\"options\"]],[24,[\"search\"]],[24,[\"searchField\"]],[24,[\"placeholder\"]],[24,[\"selected\"]],[24,[\"loadingMessage\"]],[24,[\"renderInPlace\"]],[24,[\"onchange\"]],[24,[\"onblur\"]],[24,[\"onclose\"]]]],{\"statements\":[[0,\"  \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-bootstrap/components/ric-select/template.hbs"
    }
  });

  _exports.default = _default;
});