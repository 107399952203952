define('ember-contextual-table/components/dt-column-cell', ['exports', 'ember-contextual-table/templates/dt-column-cell'], function (exports, _dtColumnCell) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _dtColumnCell.default,
    tagName: 'td',
    classNames: ['contextual-cell']

  });
});