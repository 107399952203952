define("site/routes/authorized", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-ric-utils/mixins/route-authorization", "site/config/environment"], function (_exports, _authenticatedRouteMixin, _routeAuthorization, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var modulePrefix = _environment.default.modulePrefix;

  var _default = Ember.Route.extend(_routeAuthorization.default, _authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    routerService: Ember.inject.service('router'),
    routeKey: Ember.computed('modulePrefix', 'routeName', '_routeKey', function () {
      var routeName = Ember.get(this, 'routeName');
      return "".concat(modulePrefix, ".").concat(Ember.getWithDefault(this, '_routeKey', routeName));
    }),
    parentRoute: Ember.computed('router.currentRouteName', function () {
      var owner = Ember.getOwner(this);
      var segments = this.routeName.split('.');
      var parentRoute;

      while (segments.pop() && segments.length) {
        parentRoute = owner.lookup("route:".concat(segments.join('.')));

        if (typeof parentRoute.reload === "function") {
          return parentRoute;
        }
      }

      return owner.lookup('route:site');
    }),

    /**
      Triggers authentication; by default this method transitions to the
      `authenticationRoute`. In case the application uses an authentication
      mechanism that does not use an authentication route, this method can be
      overridden.
       @method triggerAuthentication
      @protected
    */
    triggerAuthentication: function triggerAuthentication() {
      var authenticationRoute = Ember.get(this, 'authenticationRoute');
      (false && !(Ember.get(this, 'routeName') !== authenticationRoute) && Ember.assert('The route configured as Configuration.authenticationRoute cannot implement the AuthenticatedRouteMixin mixin as that leads to an infinite transitioning loop!', Ember.get(this, 'routeName') !== authenticationRoute));
      this.transitionToExternal(authenticationRoute);
    }
  });

  _exports.default = _default;
});