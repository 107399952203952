define('ember-ric-utils/components/phone-input/component', ['exports', 'ember-ric-utils/components/phone-input/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var phoneRegExp = new RegExp('^([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})$');

  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'input',
    attributeBindings: ['type', 'value', 'disabled'],
    classNames: ['form-control'],
    type: 'tel',

    property: null,
    model: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var property = this.get('property');
      var value = this._normalize(this.get('model.' + property));

      this.set('value', this._format(value));
    },
    keyUp: function keyUp(event) {
      var value = this._normalize(event.target.value);
      var property = this.get('property');

      this.set('model.' + property, value);
      this.set('value', this._format(value));
    },
    _normalize: function _normalize(value) {
      return (value || '').replace(/[^0-9]+/g, '').slice(0, 10);
    },
    _format: function _format(value) {
      var match = (value || '').match(phoneRegExp);

      if (match[1].length === 0) {
        return '';
      } else if (match[1].length < 3) {
        return '(' + match[1];
      } else if (match[2].length < 3) {
        return '(' + match[1] + ') ' + match[2];
      } else {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
  });
});