define('ember-ric-bootstrap/components/ric-form/component', ['exports', 'ember-ric-bootstrap/components/ric-form/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    class: 'row',

    model: null,
    fullScreen: true,
    onSubmit: null,
    onCancel: null

  });
});