define("site/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('groups', function () {
      this.route('index', {
        path: ''
      }, function () {
        this.route('new');
        this.route('edit', {
          path: ':group_id/edit'
        });
        this.route('delete', {
          path: ':group_id/delete'
        });
      });
      this.route('show', {
        path: ':group_id'
      }, function () {
        this.route('permissions', function () {
          this.route('new');
          this.route('delete', {
            path: ':permission_id/delete'
          });
        });
        this.route('members', function () {
          this.route('new');
          this.route('delete', {
            path: ':group_membership_id/delete'
          });
        });
      });
    });
    this.route('sections', function () {
      this.route('index', {
        path: ''
      }, function () {
        this.route('new');
        this.route('edit', {
          path: ':section_id/edit'
        });
        this.route('delete', {
          path: ':section_id/delete'
        });
        this.route('menus', {
          path: ':section_id/menus'
        }, function () {
          this.route('new');
          this.route('edit', {
            path: ':menu_id/edit'
          });
          this.route('delete', {
            path: ':menu_id/delete'
          });
        });
      });
    });
    this.route('settings', function () {
      this.route('index', {
        path: ''
      }, function () {
        this.route('new');
        this.route('edit', {
          path: ':setting_id/edit'
        });
        this.route('delete', {
          path: ':setting_id/delete'
        });
      });
    });
    this.route('users', function () {
      this.route('index', {
        path: ''
      }, function () {
        this.route('new');
        this.route('edit', {
          path: ':user_id/edit'
        });
        this.route('delete', {
          path: ':user_id/delete'
        });
      });
    });
  });

  _exports.default = _default;
});