define("ember-ric-bootstrap/components/ric-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pU6kjmrc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"modal-overlays\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ember-modal-overlay\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"ember-modal-dialog \",[22,\"class\"]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"close-button-wrapper\"],[8],[0,\"\\n        \"],[1,[28,\"close-button\",null,[[\"action\"],[[24,[\"onClose\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-bootstrap/components/ric-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});