define('ember-ric-utils/components/radio-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    classNames: ['radio-button'],
    attributeBindings: ['checked', 'disabled'],

    type: 'radio',
    name: null,
    target: null,

    checked: Ember.computed('target', 'selection', function () {
      var target = this.get('target');
      var selection = this.get('selection');

      return target === selection;
    }),

    click: function click() {
      this.set('selection', this.get('target'));
    }
  });
});