define('ember-contextual-table/components/dt-sortable-column-footer', ['exports', 'ember-contextual-table/components/dt-sortable-column-base'], function (exports, _dtSortableColumnBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dtSortableColumnBase.default.extend({
    tagName: 'td',
    footer: true,
    classNames: ['contextual-sortable-footer-cell']
  });
});