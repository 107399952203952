define("ember-ric-bootstrap/components/ric-fieldset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GUKXPMrh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"legend\"]]],null,{\"statements\":[[0,\"  \"],[7,\"legend\",true],[8],[1,[22,\"legend\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-bootstrap/components/ric-fieldset/template.hbs"
    }
  });

  _exports.default = _default;
});