define("ember-route-task-helper/utils/route-task", ["exports", "ember-concurrency/-task-property"], function (_exports, _taskProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.routeTaskFromRouterService = routeTaskFromRouterService;
  _exports.default = routeTask;
  _exports.findTaskInCurrentRouteHierarchy = findTaskInCurrentRouteHierarchy;

  function routeTaskFromRouterService(router, taskName) {
    var task = findTaskInCurrentRouteHierarchy(router, taskName);
    (false && !(task) && Ember.assert("[ember-route-task-helper] Unable to find task ".concat(taskName), task));

    for (var _len = arguments.length, params = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      params[_key - 2] = arguments[_key];
    }

    if (params.length) {
      return task._curry.apply(task, params);
    }

    return task;
  }

  function routeTask(context, taskName) {
    var router = Ember.getOwner(context).lookup('service:router');
    (false && !(router) && Ember.assert('[ember-route-task-helper] Unable to lookup router service', router));

    for (var _len2 = arguments.length, params = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
      params[_key2 - 2] = arguments[_key2];
    }

    return routeTaskFromRouterService.apply(void 0, [router, taskName].concat(params));
  }

  function findTaskInCurrentRouteHierarchy(router, taskName) {
    var owner = Ember.getOwner(router);
    var routeSegments = Ember.get(router, 'currentRouteName').split('.');

    for (var i = routeSegments.length; i >= 0; i--) {
      var routeName = routeSegments.slice(0, i).join('.') || 'application';
      var route = owner.lookup("route:".concat(routeName));
      var task = Ember.get(route, taskName);

      if (task instanceof _taskProperty.Task) {
        return task;
      }
    }

    return null;
  }
});