define("ember-preferences/computed", ["exports", "@babel/runtime/helpers/esm/typeof", "ember-preferences/storage/expirable"], function (_exports, _typeof2, _expirable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = preference;

  function calculateValue(target, value, options) {
    if (typeof value === 'undefined' || value === null) {
      if (typeof options.defaultValue === 'function') {
        return options.defaultValue.call(target);
      } else {
        return options.defaultValue;
      }
    }

    return value;
  }
  /**
   * @public
   *
   * Computed property which extends the capabilities of a preference key by
   * adding the possibility of returning a default value.
   *
   * When the computed property is set the value is automatically stored in the
   * preference store.
   *
   * When `null` or `undefined` is set the computed property returns its default
   * value.
   *
   * @example
   *
   *   import Component from '@ember/component';
   *   import { computed } from '@ember/object';
   *   import { inject as service } from '@ember/service';
   *   import preference from 'ember-preferences/computed';
   *
   *   export default Component.extend({
   *     preferences: service(),
   *     foo: preference('bar')
   *   });
   *
   * @example <caption>With default values</caption>
   *
   *   import Component from '@ember/component';
   *   import { computed } from '@ember/object';
   *   import { inject as service } from '@ember/service';
   *   import preference from 'ember-preferences/computed';
   *
   *   export default Component.extend({
   *     preferences: service(),
   *     foo: preference('bar', { defaultValue: 'hello world!' })
   *   });
   *
   * @example <caption>With a function as default value</caption>
   *
   *   import Component from '@ember/component';
   *   import { computed } from '@ember/object';
   *   import { inject as service } from '@ember/service';
   *   import preference from 'ember-preferences/computed';
   *
   *   export default Component.extend({
   *     preferences: service(),
   *     foo: preference('bar', { defaultValue() { return ['an', 'array']; } })
   *   });
   *
   * @example <caption>With expiration date</caption>
   *
   *   import Component from '@ember/component';
   *   import { computed } from '@ember/object';
   *   import { inject as service } from '@ember/service';
   *   import preference from 'ember-preferences/computed';
   *
   *   const ONE_DAY = 1 * 60 * 60 * 1000;
   *
   *   export default Component.extend({
   *     foo: preference('bar', { expires() { return +new Date() + ONE_DAY; } })
   *   });
   *
   * @example <caption>Optional dependentKey</caption>
   *
   *   import Component from '@ember/component';
   *   import { computed } from '@ember/object';
   *   import { inject as service } from '@ember/service';
   *   import preference from 'ember-preferences/computed';
   *
   *   export default Component.extend({
   *     // `foo` is going to be used as the dependentKey
   *     foo: preference(),
   *
   *     // `bar` is going to be used as the dependentKey
   *     bar: preference({ defaultValue: 'qux' })
   *   });
   *
   * @param {String|Object} dependentKey - Key from preferences to subscribe to. If a key is not provided then a hash with options can be passed.
   * @param {Object} options - Additional options
   * @param {Function|Any} options.defaultValue - Default value to return when the preference value is null or undefined
   * @param {Function} options.expires - Function that returns the absolute expiration date - time in milliseconds since the UNIX epoch
   * @return {ComputedProperty}
   */


  function preference(dependentKey, options) {
    if ((0, _typeof2.default)(dependentKey) === 'object') {
      options = dependentKey;
      dependentKey = null;
    }

    options = options || {};
    var cp = new Ember.ComputedProperty({
      get: function get(key) {
        var _this$constructor$met = this.constructor.metaForProperty(key),
            preferencesKey = _this$constructor$met.preferencesKey;

        var value = this.get(preferencesKey);
        return calculateValue(this, value, options);
      },
      set: function set(key, value) {
        var _this$constructor$met2 = this.constructor.metaForProperty(key),
            preferencesKey = _this$constructor$met2.preferencesKey;

        if (typeof options.expires === 'function') {
          value = (0, _expirable.expirable)(options.expires(), value);
        }

        this.set(preferencesKey, value);
        return calculateValue(this, value, options);
      }
    }); // We need to define the dependent key when the compute property is being
    // setup so we know the property key name used.
    //
    // The `setup` hook is defined in the `Descriptor` class.
    // See https://github.com/emberjs/ember.js/blob/38514aaa55b2bf088f61c90f3889906d944b5693/packages/ember-metal/lib/properties.js#L161

    cp.setup = function (target, targetKey) {
      var key = "preferences.".concat(dependentKey || targetKey);
      this.property(key);
      this.meta({
        preferencesKey: key
      });
    };

    return cp;
  }
});