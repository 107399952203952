define("site/components/ldap-user-lookup", ["exports", "site/validations/ldap-user-lookup", "ember-changeset-validations", "ember-changeset"], function (_exports, _ldapUserLookup, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    messages: Ember.inject.service('flashMessages'),
    changeset: null,
    records: null,
    lookupOnly: true,
    isInvalid: Ember.computed.alias('changeset.isInvalid'),
    isLoading: false,
    submitDisabled: Ember.computed.or('isInvalid', 'isLoading'),
    searchPerformed: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.changeset = new _emberChangeset.default({
        search: ''
      }, (0, _emberChangesetValidations.default)(_ldapUserLookup.default), _ldapUserLookup.default);
      this.changeset.validate();
      this.records = Ember.ArrayProxy.create({
        content: []
      });
    },
    actions: {
      performLookup: function performLookup() {
        var _this = this;

        var store = this.get('store');
        var messages = this.get('flashMessages');
        var search = this.get('changeset.search').toString();
        var submitDisabled = this.get('submitDisabled');

        if (submitDisabled) {
          return;
        }

        this.set('isLoading', true);
        this.set('searchPerformed', false);
        store.query('site-ldap-user', {
          filter: {
            search: search
          }
        }).then(function (data) {
          _this.set('records.content', data.toArray());
        }).catch(function () {
          messages.error('Failed to perform lookup');
        }).finally(function () {
          _this.set('isLoading', false);

          _this.set('searchPerformed', true);

          _this.set('search', search);
        });
      },
      makeSelection: function makeSelection(siteLdapUser) {
        var action = this.get('onSelect');
        var store = this.get('store');
        var messages = this.get('messages');
        var lookupOnly = this.get('lookupOnly');

        if (lookupOnly) {
          return action(siteLdapUser);
        }

        var attributes = siteLdapUser.getProperties('firstName', 'lastName', 'userId', 'email', 'phone', 'department', 'title', 'position');
        attributes.phone = (attributes.phone || '').replace(/[^0-9]+/g, '').slice(0, 10);
        attributes.status = 'Internal';
        store.query('site-user', {
          filter: {
            user_id: attributes.userId
          },
          lookup: true,
          fields: {
            'site-users': 'id,first-name,last-name,department,email,phone'
          }
        }).then(function (records) {
          if (records.get('length') === 1) {
            action(records.get('firstObject'));
          } else {
            return store.createRecord('site-user', attributes).save().then(function (model) {
              action(model);
            });
          }
        }).catch(function (error) {
          messages.error('Failed to assign user');
          Ember.Logger.error(error);
        });
      }
    }
  });

  _exports.default = _default;
});