define("ember-preferences/storage/serializable", ["exports", "ember-preferences/storage/decorator"], function (_exports, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_decorator.default, {
    setItem: function setItem(key, value) {
      return this._super(key, JSON.stringify(value));
    },
    getItem: function getItem(key) {
      var value = this._super(key);

      if (typeof value === 'undefined' || value === null) {
        return value;
      }

      return JSON.parse(value);
    }
  });

  _exports.default = _default;
});