define("ember-preferences/storage/decorator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    content: null,
    setItem: function setItem(key, value) {
      return this.get('content').setItem(key, value);
    },
    getItem: function getItem(key) {
      return this.get('content').getItem(key);
    },
    clear: function clear() {
      this.get('content').clear();
    },
    removeItem: function removeItem(key) {
      this.get('content').removeItem(key);
    }
  });

  _exports.default = _default;
});