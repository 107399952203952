define('ember-contextual-table/components/data-filterer', ['exports', 'ember-contextual-table/templates/data-filterer', 'ember-contextual-table/util/diactricts'], function (exports, _dataFilterer, _diactricts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A;
  var Component = Ember.Component;
  var isEmpty = Ember.isEmpty;
  var get = Ember.get;
  var computed = Ember.computed;


  function createFieldFilter(fieldName, filter) {
    return { fieldName: fieldName, filter: filter };
  }

  function internalFilterer(data, filterFields) {
    if (isEmpty(filterFields)) {
      return data;
    }

    return data.filter(function (item) {
      return itemFilterer(item, filterFields);
    });
  }

  function itemFilterer(item, filterFields) {
    var satisfiedFilters = filterFields.filter(function (filterField) {
      var fieldName = get(filterField, 'fieldName');
      var filter = get(filterField, 'filter');

      return (0, _diactricts.diacriticsInsensitiveMatcher)(get(item, fieldName), filter);
    });

    return satisfiedFilters.length === filterFields.length;
  }

  exports.default = Component.extend({
    layout: _dataFilterer.default,
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      if (isEmpty(this.get('fieldFilters'))) {
        this.set('fieldFilters', A());
      }
    },


    filteredData: computed('data.[]', 'filterer', 'fieldFilters.[]', function () {
      if (isEmpty(this.get('data'))) {
        return [];
      }

      var filterer = this.get('filterer') || internalFilterer;

      return filterer(this.get('data').slice(), this.get('fieldFilters'));
    }),

    actions: {
      onfilterfieldupdated: function onfilterfieldupdated(fieldName, filter) {
        var remainingFilters = this.get('fieldFilters').filter(function (item) {
          return get(item, 'fieldName') !== fieldName;
        });

        if (!isEmpty(filter)) {
          remainingFilters.push(createFieldFilter(fieldName, filter));
        }

        this.set('fieldFilters', remainingFilters);
      }
    }
  });
});