define('ember-ric-utils/mixins/search-term', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    searchTerm: null,
    search: Ember.computed('_search', {
      get: function get() {
        return this.get('_search');
      },
      set: function set(_, value) {
        var applySearch = function applySearch() {
          this.set('searchTerm', this.get('_search'));
        };

        this.set('_search', value);

        Ember.run.debounce(this, applySearch, 250);
      }
    })

  });
});