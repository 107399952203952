define('ember-contextual-table/components/pager-component', ['exports', 'ember-contextual-table/templates/pager-component'], function (exports, _pagerComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _pagerComponent.default,
    classNames: ['contextual-pager-component'],

    previousDisabled: computed('currentPage', function () {
      return this.get('currentPage') <= 1;
    }),

    previousButtonClass: computed('previousDisabled', function () {
      return this.get('previousDisabled') ? 'disabled' : '';
    }),

    nextButtonClass: computed('nextDisabled', function () {
      return this.get('nextDisabled') ? 'disabled' : '';
    }),

    previousLabel: '<<',
    nextLabel: '>>',

    actions: {
      previous: function previous(e) {
        e.preventDefault();
        if (this.get('previousDisabled')) {
          return;
        }

        (this.get('previous') || function () {})();
      },
      next: function next(e) {
        e.preventDefault();
        if (this.get('nextDisabled')) {
          return;
        }

        (this.get('next') || function () {})();
      }
    }
  });
});