define("site/components/site-user-form", ["exports", "ember-ric-utils/mixins/form-base"], function (_exports, _formBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATUS_OPTIONS = ['Internal', 'External'];

  var _default = Ember.Component.extend(_formBase.default, {
    settings: Ember.inject.service(),
    showPassword: Ember.computed('settings.siteUsers.showPassword', function () {
      var showPassword = this.get('settings.siteUsers.showPassword');

      if (showPassword === undefined) {
        return true;
      }

      return showPassword;
    }),
    statusOptions: Ember.computed(function () {
      return STATUS_OPTIONS;
    }),
    isNew: Ember.computed.alias('changeset._content.isNew'),
    actions: {
      updateUser: function updateUser(changeset, user) {
        var attributes = user.getProperties('firstName', 'lastName', 'position', 'title', 'department', 'institution', 'email', 'phone', 'userId');
        changeset.setProperties(attributes);
        changeset.set('active', 1);
        changeset.set('status', STATUS_OPTIONS[0]); // changeset.set('firstName', attributes.firstName);
        // changeset.set('lastName', attributes.lastName);
        // changeset.set('department', attributes.department);
        // changeset.set('email', attributes.email);
        // changeset.set('phone', attributes.phone);
        // changeset.set('title', attributes.title);
        // changeset.set('position', attributes.position);
      },
      updateSelectedGroups: function updateSelectedGroups(changeset, id) {
        var groupIds = Ember.get(changeset, 'groupIds');
        var index = groupIds.indexOf(id);

        if (index > -1) {
          groupIds.removeAt(index);
        } else {
          groupIds.push(id);
        }
      }
    }
  });

  _exports.default = _default;
});