define('ember-ric-utils/mixins/route-authorization', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    behavior: Ember.inject.service(),
    messages: Ember.inject.service('flashMessages'),

    unableRoute: 'application',

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var _EmberGetProperties = Ember.getProperties(this, 'behavior', 'messages', 'unableRoute', 'routeKey'),
          behavior = _EmberGetProperties.behavior,
          messages = _EmberGetProperties.messages,
          unableRoute = _EmberGetProperties.unableRoute,
          routeKey = _EmberGetProperties.routeKey;

      if (behavior.isUnable(routeKey)) {
        messages.error('Your are not authorized to access this route');

        if (Ember.getOwner(this).mountPoint) {
          this.transitionToExternal(unableRoute);
        } else {
          this.transitionTo(unableRoute);
        }
      }
    }
  });
});