define('ember-string-helpers/helpers/upper-case', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.upperCase = upperCase;
	function upperCase(params) {
		return params[0] && typeof params[0] === 'string' ? params[0].toUpperCase() : params[0];
	}

	exports.default = Ember.Helper.helper(upperCase);
});