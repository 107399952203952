define("ember-contextual-table/templates/dt-selection-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IQu9kJDJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"change\",\"isRowSelected\",\"row\",\"rowIndex\",\"body\"],[[28,\"action\",[[23,0,[]],\"change\"],null],[28,\"readonly\",[[24,[\"isRowSelected\"]]],null],[24,[\"row\"]],[24,[\"rowIndex\"]],true]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"type\",\"change\",\"checked\"],[\"checkbox\",[28,\"action\",[[23,0,[]],\"change\"],[[\"value\"],[\"target.checked\"]]],[28,\"readonly\",[[24,[\"isRowSelected\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-contextual-table/templates/dt-selection-column.hbs"
    }
  });

  _exports.default = _default;
});