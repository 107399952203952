define("ember-preferences/storage/compressible", ["exports", "ember-preferences/storage/decorator"], function (_exports, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global LZString */

  /**
   * @private
   */
  var _default = Ember.Object.extend(_decorator.default, {
    configuration: null,
    getItem: function getItem(key) {
      var obj = this._super(key);

      if (this.isWhiteListed(key)) {
        return LZString.decompressFromUTF16(obj);
      }

      return obj;
    },
    setItem: function setItem(key, value) {
      var compressed;

      if (this.isWhiteListed(key)) {
        compressed = LZString.compressToUTF16(value);
      } else {
        compressed = value;
      }

      this._super(key, compressed);

      return value;
    },
    isWhiteListed: function isWhiteListed(key) {
      var config = this.get('configuration');
      return config[key] && config[key].compression;
    }
  });

  _exports.default = _default;
});