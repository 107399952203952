define("site/controllers/groups/index/edit", ["exports", "site/validations/create-site-group"], function (_exports, _createSiteGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    EditSiteGroupValidation: _createSiteGroup.default
  });

  _exports.default = _default;
});