define("ember-bootstrap/mixins/sub-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class SubComponent
   * @namespace Mixins
   * @deprecated
   * @private
   */
  var _default = Ember.Mixin.create({
    targetObject: Ember.computed.alias('parentView')
  });

  _exports.default = _default;
});