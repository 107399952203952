define("site/controllers/groups/show/members", ["exports", "ember-ric-utils/mixins/search-term", "ember-ric-utils/mixins/filtered-records"], function (_exports, _searchTerm, _filteredRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_searchTerm.default, _filteredRecords.default, {
    groupMemberships: Ember.computed.alias('model.groupMemberships'),
    sortedRecords: Ember.computed.alias('groupMemberships')
  });

  _exports.default = _default;
});