define("site/routes/groups/index/new", ["exports", "site/routes/authorized", "ember-ric-utils/mixins/crud-methods"], function (_exports, _authorized, _crudMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend(_crudMethods.default, {
    breadCrumb: null,
    successMessage: 'Group created',
    failureMessage: 'Failed to create group',
    redirectUrl: 'groups',
    model: function model() {
      return Ember.get(this, 'store').createRecord('site-group');
    }
  });

  _exports.default = _default;
});