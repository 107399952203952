define("ember-ric-utils/components/ric-drop-zone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3neSNatc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"supported\"]]],null,{\"statements\":[[0,\"    Drag and drop documents onto this area to upload them or\\n\"]],\"parameters\":[]},null],[4,\"file-upload\",null,[[\"name\",\"multiple\",\"onfileadd\"],[[24,[\"name\"]],true,[28,\"action\",[[23,0,[]],\"upload\"],null]]],{\"statements\":[[0,\"                 \"],[7,\"a\",true],[10,\"id\",\"upload\"],[10,\"tabindex\",\"0\"],[8],[1,[22,\"prompt\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"if\",[[24,[\"queue\",\"files\",\"length\"]],\"\",\"hidden\"],null]],[8],[0,\"\\n    Uploading \"],[1,[24,[\"queue\",\"files\",\"length\"]],false],[0,\" files. (\"],[1,[24,[\"queue\",\"progress\"]],false],[0,\"%)\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-utils/components/ric-drop-zone/template.hbs"
    }
  });

  _exports.default = _default;
});