define('ember-contextual-table/components/dt-filterable-column-base', ['exports', 'ember-contextual-table/templates/dt-filterable-column-base'], function (exports, _dtFilterableColumnBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var observer = Ember.observer;
  exports.default = Component.extend({
    layout: _dtFilterableColumnBase.default,

    filterValueChanged: observer('filterValue', function () {
      var actionHandler = this.get('filterinformationupdated') || function () {};
      actionHandler(this.get('propertyName'), this.get('filterValue'));
    }),

    actions: {
      onfilterupdate: function onfilterupdate(filterValue) {
        this.set('filterValue', filterValue);
      }
    }
  });
});