define('ember-ric-utils/mixins/filtered-records', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    filteredRecords: Ember.computed('sortedRecords', 'searchTerm', function () {
      var records = this.get('sortedRecords');
      var search = this.get('searchTerm');
      var filterOn = this.get('filterOn') || 'searchIndex';

      if (Ember.isBlank(search)) {
        return records;
      }

      var term = search.replace(/[\\/$^.]/g, '');
      var filter = new RegExp(term, 'i');

      return records.filter(function (record) {
        return filter.test(Ember.get(record, filterOn));
      });
    }),

    resetController: function resetController(controller, isExisting) {
      if (isExisting) {
        Ember.setProperties(controller, { searchTerm: null });
      }
    }
  });
});