define('ember-ric-utils/components/ric-resources/component', ['exports', 'ember-ric-utils/components/ric-resources/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['ric-resources', 'col-12', 'col-xs-12'],

    showEmpty: false,
    resources: null,
    allowEdit: false,

    actions: {
      delete: function _delete(resource) {
        var _this = this;

        resource.destroyRecord().then(function () {
          _this.flashMessages.success("Record deleted");
        }).catch(function () {
          _this.flashMessages.error("Failed to delete record");
        });
      }
    }

  });
});