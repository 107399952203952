define("ember-yeti-table/components/yeti-table/head/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4kO79QBK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"row\"],[[28,\"component\",[\"yeti-table/head/row\"],[[\"sortable\",\"sortSequence\",\"onColumnClick\",\"columns\",\"theme\",\"parent\"],[[24,[\"sortable\"]],[24,[\"sortSequence\"]],[24,[\"onColumnClick\"]],[24,[\"columns\"]],[24,[\"theme\"]],[24,[\"parent\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/head/template.hbs"
    }
  });

  _exports.default = _default;
});