define('ember-contextual-table/components/row-component', ['exports', 'ember-contextual-table/templates/row-component'], function (exports, _rowComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'tr',
    layout: _rowComponent.default
  });
});