define("site/validations/create-site-setting", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    param: [(0, _validators.validatePresence)({
      presence: true
    })],
    paramValue: [(0, _validators.validatePresence)({
      presence: true
    })]
  };
  _exports.default = _default;
});