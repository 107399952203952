define('ember-ric-utils/components/ric-table/component', ['exports', 'ember-ric-utils/components/ric-table/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    /**
     * The data to render as rows.
     * @type {Array}
     * @default []
     * @property
     * @public
     */
    data: null,

    /**
     * A simple POJO of column data. Currently only supports label
     * @type {Array}
     * @property
     * @public
     */
    columns: null,

    /**
     * The current sort string for the table, expected to be passed in.
     * @type {String}
     * @default ''
     * @property
     * @public
     */
    currentSort: '',

    /**
     * If the table currently has data
     * @type {Boolean}
     * @property
     * @public
     */
    hasData: Ember.computed.gt('data.length', 0),

    init: function init() {
      this._super.apply(this, arguments);
      this.data = this.data || [];
      this.columns = this.columns || [];
    }
  });
});