define("ember-yeti-table/components/yeti-table/head/row/column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xBtpmm8A",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"visible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"th\",false],[12,\"role\",[28,\"if\",[[24,[\"sortable\"]],\"button\"],null]],[12,\"class\",[29,[[22,\"class\"],\" \",[24,[\"theme\",\"theadCell\"]],\" \",[28,\"if\",[[24,[\"sortable\"]],[24,[\"theme\",\"sorting\",\"columnSortable\"]]],null],\" \",[28,\"if\",[[24,[\"isSorted\"]],[24,[\"theme\",\"sorting\",\"columnSorted\"]]],null],\" \",[28,\"if\",[[24,[\"isAscSorted\"]],[24,[\"theme\",\"sorting\",\"columnSortedAsc\"]]],null],\" \",[28,\"if\",[[24,[\"isDescSorted\"]],[24,[\"theme\",\"sorting\",\"columnSortedDesc\"]]],null]]]],[12,\"onclick\",[28,\"if\",[[24,[\"sortable\"]],[28,\"action\",[[23,0,[]],[24,[\"onClick\"]],[23,0,[]]],null]],null]],[13,1],[8],[0,\"\\n    \"],[14,2,[[28,\"hash\",null,[[\"isSorted\",\"isAscSorted\",\"isDescSorted\"],[[24,[\"isSorted\"]],[24,[\"isAscSorted\"]],[24,[\"isDescSorted\"]]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/head/row/column/template.hbs"
    }
  });

  _exports.default = _default;
});