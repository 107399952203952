define("ember-yeti-table/-private/utils/sorting-utils", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mergeSort = mergeSort;
  _exports.sortMultiple = sortMultiple;
  _exports.compareValues = compareValues;

  function merge(left, right, comparator) {
    var mergedArray = [];
    var leftIndex = 0;
    var rightIndex = 0;

    while (leftIndex < left.length && rightIndex < right.length) {
      var comparison = comparator(left[leftIndex], right[rightIndex]);

      if (comparison <= 0) {
        mergedArray.push(left[leftIndex]);
        leftIndex++;
      } else {
        mergedArray.push(right[rightIndex]);
        rightIndex++;
      }
    }

    if (leftIndex < left.length) {
      mergedArray.splice.apply(mergedArray, [mergedArray.length, 0].concat((0, _toConsumableArray2.default)(left.slice(leftIndex))));
    }

    if (rightIndex < right.length) {
      mergedArray.splice.apply(mergedArray, [mergedArray.length, 0].concat((0, _toConsumableArray2.default)(right.slice(rightIndex))));
    }

    return mergedArray;
  }
  /**
   * An implementation of the standard merge sort algorithm.
   *
   * This is necessary because we need a stable sorting algorithm that accepts
   * a general comparator. The built in sort function and Ember's sort functions
   * are not stable, and `_.sortBy` doesn't take a general comparator. Ideally
   * lodash would add a `_.sort` function whose API would mimic this function's.
   *
   * @function
   * @param {Array} array The array to be sorted
   * @param {Comparator} comparator The comparator function to compare elements with.
   * @return {Array} A sorted array
   */


  function mergeSort(array) {
    var comparator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Ember.compare;

    if (array.length <= 1) {
      return array;
    }

    var middleIndex = Math.floor(array.length / 2);
    var leftArray = mergeSort(array.slice(0, middleIndex), comparator);
    var rightArray = mergeSort(array.slice(middleIndex), comparator);
    return merge(leftArray, rightArray, comparator);
  }

  function sortMultiple(itemA, itemB, sorts, compare) {
    var compareValue;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = sorts[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _step$value = _step.value,
            prop = _step$value.prop,
            direction = _step$value.direction;
        var valueA = Ember.get(itemA, prop);
        var valueB = Ember.get(itemB, prop);
        compareValue = direction === 'asc' ? compare(valueA, valueB) : -compare(valueA, valueB);

        if (compareValue !== 0) {
          break;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return compareValue;
  }

  function isExactlyNaN(value) {
    return typeof value === 'number' && isNaN(value);
  }

  function isEmpty(value) {
    return Ember.isNone(value) || isExactlyNaN(value);
  }

  function orderEmptyValues(itemA, itemB) {
    var aIsEmpty = isEmpty(itemA);
    var bIsEmpty = isEmpty(itemB);

    if (aIsEmpty && !bIsEmpty) {
      return -1;
    } else if (bIsEmpty && !aIsEmpty) {
      return 1;
    } else if (Ember.isNone(itemA) && isExactlyNaN(itemB)) {
      return -1;
    } else if (isExactlyNaN(itemA) && Ember.isNone(itemB)) {
      return 1;
    } else {
      return 0;
    }
  }

  function compareValues(itemA, itemB) {
    if (isEmpty(itemA) || isEmpty(itemB)) {
      return orderEmptyValues(itemA, itemB);
    }

    return Ember.compare(itemA, itemB);
  }
});