define("site/models/site-group-membership", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    group: belongsTo('site-group'),
    user: belongsTo('site-user'),
    name: Ember.computed.alias('user.name'),
    email: Ember.computed.alias('user.email'),
    userId: Ember.computed.alias('user.userId'),
    searchIndex: Ember.computed('name', 'email', function () {
      return "".concat(this.get('name'), "|").concat(this.get('email'));
    })
  });

  _exports.default = _default;
});