define("ember-ric-bootstrap/components/bs-datepicker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8B2zxyR3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"label\"]],\"\",\"hidden\"],null]]]],[8],[0,\"\\n  \"],[1,[22,\"label\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"bootstrap-datepicker\",null,[[\"value\",\"format\",\"placeholder\",\"clearBtn\",\"todayHighlight\",\"forceParse\",\"autoclose\",\"readonly\",\"class\"],[[28,\"mut\",[[24,[\"value\"]]],null],\"yyyy-mm-dd\",[24,[\"placeholder\"]],true,true,true,true,[24,[\"isDisabled\"]],\"form-control\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-bootstrap/components/bs-datepicker/template.hbs"
    }
  });

  _exports.default = _default;
});