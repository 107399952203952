define('ember-ric-bootstrap/components/ric-select/component', ['exports', 'ember-ric-bootstrap/components/ric-select/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['form-group', 'col-12', 'col-xs-12'],

    label: null,
    disabled: false,
    options: null,
    search: null,
    searchField: null,
    placeholder: null,
    selected: null,
    loadingMessage: 'Loading...',
    renderInPlace: true,
    onchange: function onchange() {},
    onblur: function onblur() {},
    onclose: function onclose() {}

  });
});