define('ember-ric-utils/mixins/crud-methods', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getEngineRouteName = function getEngineRouteName(engine, routeName) {
    if (engine.routable) {
      var prefix = engine.mountPoint;

      if (routeName === 'application') {
        return prefix;
      } else {
        return prefix + '.' + routeName;
      }
    }

    return routeName;
  };

  exports.default = Ember.Mixin.create({

    flashMessages: Ember.inject.service(),

    successMessage: 'Entry created',
    failureMessage: 'Failed to create entry',
    redirectUrl: 'index',

    /*
      @method modelFor
      @param {String} name the name of the route
      @return {Object} the model object
      @since 1.0.0
      @public
    */
    modelFor: function modelFor(_name) {
      var name = void 0;
      var owner = Ember.getOwner(this);
      var transition = this._router && this._router._routerMicrolib ? this._router._routerMicrolib.activeTransition : undefined;

      // Only change the route name when there is an active transition.
      // Otherwise, use the passed in route name.
      if (owner.routable && transition !== null) {
        name = getEngineRouteName(owner, _name);
      } else {
        name = _name;
      }

      var route = owner.lookup('route:' + _name);
      // If we are mid-transition, we want to try and look up
      // resolved parent contexts on the current transitionEvent.
      if (transition) {
        var modelLookupName = route && route.routeName || name;
        if (transition.resolvedModels.hasOwnProperty(modelLookupName)) {
          return transition.resolvedModels[modelLookupName];
        }
      }

      return route && route.currentModel;
    },
    _parentRoute: function _parentRoute() {
      var parentRouteName = this.get('_router.currentRoute.parent.name') || this.parentRouteName;

      if (parentRouteName) {
        return Ember.getOwner(this).lookup('route:' + parentRouteName);
      }
    },
    _model: function _model() {
      return this._modelForRoute();
    },
    _modelForRoute: function _modelForRoute() {
      return this.modelFor(this.routeName);
    },
    _beforeCreate: function _beforeCreate() /* model */{},
    _afterCreate: function _afterCreate() /* model */{},
    _beforeUpdate: function _beforeUpdate() /* model */{},
    _afterUpdate: function _afterUpdate() /* model */{},
    _reloadParent: function _reloadParent() {
      var parentRoute = this.parentRoute || this._parentRoute();

      if (parentRoute && typeof parentRoute.reload === "function") {
        parentRoute.reload();
      }
    },
    _getBaseError: function _getBaseError(error) {
      if (error.errors) {
        return error.errors.filter(function (error) {
          return error.source && error.source.pointer && error.source.pointer === '/data/attributes/base';
        }).map(function (error) {
          return error.title;
        })[0];
      }
    },


    actions: {
      create: function create(changeset) {
        var _this = this;

        var messages = this.get('flashMessages');

        var _getProperties = this.getProperties('successMessage', 'failureMessage', 'redirectUrl'),
            successMessage = _getProperties.successMessage,
            failureMessage = _getProperties.failureMessage,
            redirectUrl = _getProperties.redirectUrl;

        this._beforeCreate(changeset);

        return changeset.execute().save().then(function () {
          messages.success(successMessage);
          _this._afterCreate(changeset);
          _this._reloadParent();
          _this.transitionTo(redirectUrl);
        }).catch(function (error) {
          var baseError = _this._getBaseError(error);
          messages.error(baseError || failureMessage);
          // eslint-disable-next-line no-console
          console.error(error);
        });
      },
      update: function update(changeset) {
        var _this2 = this;

        var messages = this.get('flashMessages');

        var _getProperties2 = this.getProperties('successMessage', 'failureMessage', 'redirectUrl'),
            successMessage = _getProperties2.successMessage,
            failureMessage = _getProperties2.failureMessage,
            redirectUrl = _getProperties2.redirectUrl;

        this._beforeUpdate(changeset);

        return changeset.execute().save().then(function () {
          messages.success(successMessage);
          _this2._afterUpdate(changeset);
          _this2._reloadParent();
          _this2.transitionTo(redirectUrl);
        }).catch(function (error) {
          var baseError = _this2._getBaseError(error);
          messages.error(baseError || failureMessage);
          // eslint-disable-next-line no-console
          console.error(error);
        });
      },
      delete: function _delete(model) {
        var _this3 = this;

        var messages = this.get('flashMessages');

        var _getProperties3 = this.getProperties('successMessage', 'failureMessage', 'redirectUrl'),
            successMessage = _getProperties3.successMessage,
            failureMessage = _getProperties3.failureMessage,
            redirectUrl = _getProperties3.redirectUrl;

        return model.destroyRecord().then(function () {
          messages.success(successMessage);
          _this3._reloadParent();
          _this3.transitionTo(redirectUrl);
        }).catch(function (error) {
          var baseError = _this3._getBaseError(error);
          messages.error(baseError || failureMessage);
          // eslint-disable-next-line no-console
          console.error(error);
        });
      },
      cancel: function cancel(modelOrChangeset) {
        var redirectUrl = this.get('redirectUrl');

        if (typeof modelOrChangeset.rollback === "function") {
          modelOrChangeset.rollback();
        } else if (typeof modelOrChangeset.rollbackAttributes === "function") {
          modelOrChangeset.rollbackAttributes();
        }

        this.transitionTo(redirectUrl);
      },
      willTransition: function willTransition() {
        var model = this._model();

        if (!model.get('isDeleted')) {
          model.rollbackAttributes();
        }
      }
    }

  });
});