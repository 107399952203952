define('ember-contextual-table/components/data-paginator', ['exports', 'ember-contextual-table/templates/data-paginator'], function (exports, _dataPaginator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _dataPaginator.default,
    currentPage: 1,
    pageSize: 10,
    classNames: ['contextual-data-paginator'],

    hasNext: computed('paginatedData.[]', 'pageSize', function () {
      return this.get('paginatedData').length === this.get('pageSize');
    }),

    offset: computed('pageSize', 'currentPage', function () {
      var pageSize = this.get('pageSize');
      var currentPage = this.get('currentPage') - 1;
      return currentPage * pageSize;
    }),

    limit: alias('pageSize'),

    paginatedData: computed('data.[]', 'pageSize', 'currentPage', function () {
      var data = this.get('data'); //TODO : Ember.assert 'data' is not null/undefined
      var pageSize = this.get('pageSize');
      var currentPage = this.get('currentPage') - 1;

      var start = currentPage * pageSize;
      var end = (currentPage + 1) * pageSize;
      return data.slice(start, end);
    }),

    fireDataRequested: function fireDataRequested() {
      var offset = this.get('offset');
      var limit = this.get('limit');
      var dataRequested = this.get('dataRequested');
      if (dataRequested) {
        dataRequested(offset, limit);
      }
    },

    actions: {
      previous: function previous() {
        this.decrementProperty('currentPage');
        this.fireDataRequested();
      },
      next: function next() {
        this.incrementProperty('currentPage');
        this.fireDataRequested();
      }
    }
  });
});