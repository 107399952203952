define('ember-ric-bootstrap/components/ric-form-actions/component', ['exports', 'ember-ric-bootstrap/components/ric-form-actions/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['form-actions', 'col-12', 'col-xs-12'],
    classNameBindings: ['fullScreen::col-md-8'],

    fullScreen: true,
    isNew: Ember.computed.alias('model.isNew'),

    submitButtonLabel: Ember.computed('isNew', function () {
      var isNew = this.get('isNew');
      return isNew ? 'Submit' : 'Save';
    }),
    submitButtonType: 'success',

    onCancel: null,

    submitDisabled: Ember.computed.or('isInvalid', 'isSaving'),
    cancelDisabled: Ember.computed.alias('isSaving'),

    isSaving: Ember.computed.alias('model.isSaving'),

    isInvalid: Ember.computed('model.isInvalid', function () {
      var model = this.get('model');

      if (model && model.isInvalid) {
        return model.get('isInvalid');
      }

      return false;
    })
  });
});