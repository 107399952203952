define("ember-bootstrap/components/base/bs-nav/item", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/toConsumableArray", "ember-bootstrap/templates/components/bs-nav/item", "ember-bootstrap/mixins/component-parent", "ember-bootstrap/utils/cp/overrideable"], function (_exports, _objectSpread2, _toConsumableArray2, _item, _componentParent, _overrideable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   Component for each item within a [Components.Nav](Components.Nav.html) component. Have a look there for examples.
  
   @class NavItem
   @namespace Components
   @extends Ember.Component
   @uses Mixins.ComponentParent
   @public
   */
  var _default = Ember.Component.extend(_componentParent.default, {
    layout: _item.default,
    classNameBindings: ['disabled', 'active'],
    tagName: 'li',
    router: Ember.inject.service(),

    /**
     * If set will wrap the item's content in a link to the given route name. Same as the `route` property of `<LinkTo>`,
     * see https://api.emberjs.com/ember/3.10/classes/LinkComponent/properties/route?anchor=route
     *
     * @property route
     * @type {string}
     * @public
     */

    /**
     * The model of a dynamic route. Same as the `model` property of `<LinkTo>`,
     * see https://api.emberjs.com/ember/3.10/classes/LinkComponent/properties/route?anchor=model
     *
     * @property model
     * @type {object|string}
     * @public
     */

    /**
     * The models of a dynamic route. Same as the `models` property of `<LinkTo>`,
     * see https://api.emberjs.com/ember/3.10/classes/LinkComponent/properties/route?anchor=models
     *
     * @property models
     * @type {array}
     * @public
     */

    /**
     * The query params of a dynamic route. Same as the `query` property of `<LinkTo>`,
     * see https://api.emberjs.com/ember/3.10/classes/LinkComponent/properties/route?anchor=query
     *
     * @property query
     * @type {object}
     * @public
     */

    /**
     * Render the nav item as disabled (see [Bootstrap docs](http://getbootstrap.com/components/#nav-disabled-links)).
     * By default it will look at any nested `link-to` components and make itself disabled if there is a disabled link.
     * See the [link-to API](http://emberjs.com/api/classes/Ember.Templates.helpers.html#toc_disabling-the-code-link-to-code-component)
     *
     * @property disabled
     * @type boolean
     * @public
     */
    disabled: (0, _overrideable.default)('_disabled', function () {
      return this.get('_disabled');
    }),
    _disabled: false,

    /**
     * Render the nav item as active.
     * By default it will look at any nested `link-to` components and make itself active if there is an active link
     * (i.e. the link points to the current route).
     * See the [link-to API](http://emberjs.com/api/classes/Ember.Templates.helpers.html#toc_handling-current-route)
     *
     * @property active
     * @type boolean
     * @public
     */
    active: (0, _overrideable.default)('_active', 'route', 'model', 'models', 'query', 'router.currentURL', function () {
      var _this$get;

      var _this$getProperties = this.getProperties('route', 'model', 'models', 'query'),
          route = _this$getProperties.route,
          model = _this$getProperties.model,
          models = _this$getProperties.models,
          query = _this$getProperties.query;

      var params = [];

      if (route) {
        params.push(route);
      }

      if (model) {
        params.push(model);
      }

      if (models) {
        params.push.apply(params, (0, _toConsumableArray2.default)(models));
      }

      if (query) {
        params.push((0, _objectSpread2.default)({
          queryParams: true
        }, query));
      }

      return params.length ? (_this$get = this.get('router')).isActive.apply(_this$get, params) : this.get('_active');
    }),
    _active: false,

    /**
     * Collection of all `Ember.LinkComponent`s that are children
     *
     * @property childLinks
     * @private
     */
    childLinks: Ember.computed.filter('children', function (view) {
      return view instanceof Ember.LinkComponent;
    }),
    activeChildLinks: Ember.computed.filterBy('childLinks', 'active'),
    hasActiveChildLinks: Ember.computed.gt('activeChildLinks.length', 0),
    disabledChildLinks: Ember.computed.filterBy('childLinks', 'disabled'),
    hasDisabledChildLinks: Ember.computed.gt('disabledChildLinks.length', 0),
    hasLink: Ember.computed.or('route', 'model', 'models', 'query'),

    /**
     * Called when clicking the nav item
     *
     * @event onClick
     * @public
     */
    onClick: function onClick() {},
    click: function click() {
      this.onClick();
    },
    init: function init() {
      this._super.apply(this, arguments);

      var _this$getProperties2 = this.getProperties('model', 'models'),
          model = _this$getProperties2.model,
          models = _this$getProperties2.models;

      (false && !(!model || !models) && Ember.assert('You cannot pass both `@model` and `@models` to a nav item component!', !model || !models));
      this.get('activeChildLinks');
      this.get('disabledChildLinks');
    },
    _observeActive: Ember.observer('activeChildLinks.[]', function () {
      Ember.run.scheduleOnce('afterRender', this, this._updateActive);
    }),
    _updateActive: function _updateActive() {
      this.set('_active', this.get('hasActiveChildLinks'));
    },
    _observeDisabled: Ember.observer('disabledChildLinks.[]', function () {
      Ember.run.scheduleOnce('afterRender', this, this._updateDisabled);
    }),
    _updateDisabled: function _updateDisabled() {
      this.set('_disabled', this.get('hasDisabledChildLinks'));
    }
  });

  _exports.default = _default;
});