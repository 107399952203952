define("ember-yeti-table/components/yeti-table/head/row/cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8FV82UIt",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"column\",\"visible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"th\",false],[12,\"class\",[29,[[22,\"class\"],\" \",[24,[\"theme\",\"theadCell\"]]]]],[13,1],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/head/row/cell/template.hbs"
    }
  });

  _exports.default = _default;
});