define('ember-ric-utils/authenticators/knock', ['exports', 'ember-simple-auth-token/authenticators/jwt'], function (exports, _jwt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jwt.default.extend({
    handleTokenRefreshFail: function handleTokenRefreshFail(refreshStatus) {
      var _this = this;

      if (refreshStatus === 0 || refreshStatus === 401 || refreshStatus === 403) {
        return this.invalidate().then(function () {
          _this.trigger('sessionDataInvalidated');
        });
      }
    }
  });
});