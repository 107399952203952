define('ember-contextual-table/components/dt-filterable-column-header', ['exports', 'ember-contextual-table/components/dt-filterable-column-base'], function (exports, _dtFilterableColumnBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dtFilterableColumnBase.default.extend({
    tagName: 'th',
    header: true,
    classNames: ['contextual-filterable-header-cell']
  });
});