define("site/routes/sections/index/edit", ["exports", "site/routes/authorized", "ember-ric-utils/mixins/crud-methods"], function (_exports, _authorized, _crudMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend(_crudMethods.default, {
    breadCrumb: null,
    successMessage: 'Section saved',
    failureMessage: 'Failed to save section',
    redirectUrl: 'sections',
    model: function model(_ref) {
      var section_id = _ref.section_id;
      return Ember.get(this, 'store').findRecord('site-section', section_id);
    }
  });

  _exports.default = _default;
});