define('ember-ric-bootstrap/components/close-button/component', ['exports', 'ember-keyboard', 'ember-ric-bootstrap/components/close-button/template'], function (exports, _emberKeyboard, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    layout: _template.default,

    tagName: 'i',
    classNames: ['fa', 'fa-times', 'pull-right'],

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'keyboardActivated', true);
    },


    abort: Ember.on((0, _emberKeyboard.keyUp)('Escape'), function () {
      this.click();
    }),

    click: function click() {
      var action = Ember.get(this, 'action');

      if (action) {
        action();
      }
    }
  });
});