define("ember-ric-utils/components/boolean-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O/nHcYke",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[8],[0,\"\\n  \"],[1,[28,\"radio-button\",null,[[\"name\",\"target\",\"selection\",\"disabled\"],[[24,[\"name\"]],true,[24,[\"value\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"Yes\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"label\",true],[8],[0,\"\\n  \"],[1,[28,\"radio-button\",null,[[\"name\",\"target\",\"selection\",\"disabled\"],[[24,[\"name\"]],false,[24,[\"value\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"No\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-utils/components/boolean-input/template.hbs"
    }
  });

  _exports.default = _default;
});