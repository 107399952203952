define('ember-ric-utils/mixins/error-logging', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    currentSession: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    raven: Ember.inject.service(),

    actions: {
      error: function error(_error) {
        var redirectUrl = Ember.getWithDefault(this, 'errorRedirectUrl', 'index');
        var messages = Ember.get(this, 'flashMessages');
        var raven = Ember.get(this, 'raven');
        var currentSession = Ember.getWithDefault(this, 'currentSession', {});
        var sessionData = Ember.getWithDefault(currentSession, 'data', {});

        var userContext = Ember.getProperties(sessionData, 'name', 'email', 'id');
        var context = Ember.getProperties(currentSession, 'isAuthenticated', 'isMasquerading', 'adminUsername', 'expiresIn');

        if (_error.isAdapterError) {
          var responseError = _error.errors[0];

          if (responseError) {
            switch (responseError.status) {
              case "404":
                messages.error("The requested record could not be found or you're not authorized to access it");
                break;
              case "401":
                messages.error("You are not authorized for this request");
                break;
              default:
                messages.error("We were unable to complete the request. We have been informed of the issue and will take a look shortly.");
                break;
            }
          } else {
            messages.error("Something went wrong. We have been informed of the issue and will take a look shortly.");
          }

          try {
            raven.callRaven("setUserContext", userContext);
            raven.callRaven("setExtraContext", context);
            raven.captureException(_error);
            raven.callRaven("showReportDialog");
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }

          this.transitionTo(redirectUrl);
        } else {
          messages.error("Something went wrong. We have been informed of the issue and will take a look shortly.");

          try {
            raven.callRaven("setUserContext", userContext);
            raven.callRaven("setExtraContext", context);
            raven.captureException(_error);
            // raven.callRaven("showReportDialog");
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
        }
      }
    }

  });
});