define("ember-preferences/storage/expirable", ["exports", "@babel/runtime/helpers/esm/typeof", "ember-preferences/storage/decorator"], function (_exports, _typeof2, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isExpirable = isExpirable;
  _exports.isExpired = isExpired;
  _exports.expirable = expirable;
  _exports.default = void 0;

  /**
   * @private
   */
  function isExpirable(value) {
    return (0, _typeof2.default)(value) === 'object' && value !== null && value.type === 'expirable';
  }
  /**
   * @private
   */


  function isExpired(value) {
    return typeof value.expirationTime === 'number' && +new Date() > value.expirationTime;
  }
  /**
   * @private
   *
   * Creates a new expirable value
   *
   * @param {Number} expirationTime - absolute time in milliseconds since UNIX epoch
   * @param {Any} value - value to store
   * @return {Object}
   */


  function expirable(expirationTime, value) {
    return {
      type: 'expirable',
      expirationTime: expirationTime,
      value: value
    };
  }

  var _default = Ember.Object.extend(_decorator.default, {
    getItem: function getItem(key) {
      var obj = this._super(key);

      if (isExpirable(obj)) {
        if (isExpired(obj)) {
          this.removeItem(key);
          return;
        }

        return obj.value;
      }

      return obj;
    },
    setItem: function setItem(key, value) {
      this._super(key, this.wrapValue(key, value));

      return value;
    },
    wrapValue: function wrapValue(key, value) {
      if (typeof this.get('expirations')[key] === 'function') {
        return expirable(this.get('expirations')[key](), value);
      } else {
        return value;
      }
    }
  });

  _exports.default = _default;
});