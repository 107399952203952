define("site/routes/groups/show", ["exports", "site/routes/authorized"], function (_exports, _authorized) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend({
    breadCrumb: {},
    model: function model(params) {
      return Ember.get(this, 'store').find('site-group', params.group_id);
    },
    afterModel: function afterModel(model) {
      this.set('breadCrumb', {
        title: model.get('name'),
        linkable: false
      });
    }
  });

  _exports.default = _default;
});