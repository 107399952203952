define('ember-string-helpers/helpers/format-date', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDate = formatDate;
  function formatDate(params, hash) {
    var date = params[0],
        formattedDate,
        inputFormat = hash.inputFormat,
        mDate = date === 'now' ? (0, _moment.default)() : (0, _moment.default)(date, inputFormat),
        format = hash.format || "dddd, MMMM Do YYYY, h:mm a";

    if (typeof _moment.default === 'undefined') {
      // eslint-disable-next-line no-console
      console.log("%c{{format-date}} moment is undefined. Formatting disabled.", "color: red" // http://www.w3schools.com/html/html_colornames.asp
      );
      return date;
    }

    if (!mDate.isValid()) {
      // eslint-disable-next-line no-console
      console.log("%c{{format-date}} invalid date.", "color: red" // http://www.w3schools.com/html/html_colornames.asp
      );
      return date;
    }

    formattedDate = mDate.format(format);

    return formattedDate;
  }

  exports.default = Ember.Helper.helper(formatDate);
});