define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "add-experiment-entry": "__2494e",
    "add-folder-entry": "__3b747",
    "add-named-entry": "__b3bb3",
    "dicom-preview": "__d21c2",
    "drop-zone": "__815fc",
    "file-browser": "__26eea",
    "links-with-follower": "__029a2",
    "login-form": "__77222",
    "navigation-toolbar": "__45137",
    "project-details": "__cd742",
    "project-header": "__7f0e1",
    "project-nav": "__1b3d7",
    "reload-button": "__5a502",
    "timeline-event-data-change": "__5998a",
    "timeline-event-project-diary-form": "__96700",
    "timeline-event-project-diary": "__5f140",
    "timeline-event-resource-change": "__b8680",
    "timeline-event-study-change": "__2ce87",
    "timeline-event-user-change": "__87bcc",
    "timeline-event": "__a11b8",
    "timeline-events": "__a057b",
    "upload-file-manager": "__2ef6b",
    "upload-manager": "__96655"
  };
  _exports.default = _default;
});