define("site/helpers/in-groups", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inGroups = inGroups;
  _exports.default = void 0;

  function inGroups(params) {
    var _params = (0, _slicedToArray2.default)(params, 2),
        collection = _params[0],
        number = _params[1];

    if (collection === undefined) {
      return [];
    }

    var totalLength = Ember.get(collection, 'length');
    var groups = Ember.A();
    var division = window.parseInt(totalLength / number);
    var modulo = totalLength % number;
    var start = 0;
    var length;

    for (var i = 0; i < number; i++) {
      length = division + (modulo > 0 && modulo > i ? 1 : 0);
      groups.addObject(collection.slice(start, start + length));
      start = start + length;
    }

    return groups;
  }

  var _default = Ember.Helper.helper(inGroups);

  _exports.default = _default;
});