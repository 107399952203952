define("ember-ric-bootstrap/components/ric-delete-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7yg2sT71",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"ric-dialog\",null,[[\"onClose\",\"class\"],[[24,[\"onCancel\"]],\"confirmation-dialog\"]],{\"statements\":[[4,\"ric-form\",null,[[\"model\",\"onSubmit\",\"onCancel\"],[[24,[\"model\"]],[24,[\"onSubmit\"]],[24,[\"onCancel\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"fieldset\"]],\"expected `f.fieldset` to be a contextual component but found a string. Did you mean `(component f.fieldset)`? ('ember-ric-bootstrap/components/ric-delete-dialog/template.hbs' @ L5:C7) \"],null]],null,{\"statements\":[[0,\"      \"],[7,\"legend\",true],[8],[1,[22,\"operation\"],false],[0,\" \"],[1,[22,\"subject\"],false],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-12 col-xs-12\"],[8],[0,\"\\n          Are you sure you want to proceed?\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"actions\"]],\"expected `f.actions` to be a contextual component but found a string. Did you mean `(component f.actions)`? ('ember-ric-bootstrap/components/ric-delete-dialog/template.hbs' @ L15:C6) \"],null]],[[\"submitButtonLabel\",\"submitButtonType\"],[[24,[\"operation\"]],\"danger\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-bootstrap/components/ric-delete-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});