define("site/models/site-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import computed from 'ember-computed-decorators';
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    menu: belongsTo('site-menu'),
    group: belongsTo('site-group'),
    r: attr('boolean'),
    c: attr('boolean'),
    u: attr('boolean'),
    d: attr('boolean'),
    s: attr('boolean'),
    a: attr('boolean'),
    sectionName: Ember.computed.alias('menu.sectionName'),
    searchIndex: Ember.computed('menu.name', 'menu.url', function () {
      return "".concat(this.get('menu.name'), "|").concat(this.get('menu.url'));
    })
  });

  _exports.default = _default;
});