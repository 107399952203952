define("site/validators/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validatePassword;

  function validatePassword()
  /* options = {} */
  {
    return function ()
    /* key, newValue, oldValue, changes, content */
    {
      return true;
    };
  }
});