define("site/templates/components/site-group-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xOUaNCqb",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"ric-dialog\",null,[[\"onClose\",\"class\"],[[24,[\"onClose\"]],\"site-groups--new\"]],{\"statements\":[[4,\"ric-form\",null,[[\"model\",\"fullScreen\",\"onSubmit\",\"onCancel\"],[[24,[\"changeset\"]],true,[24,[\"onSubmit\"]],[24,[\"onCancel\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"fieldset\"]],\"expected `f.fieldset` to be a contextual component but found a string. Did you mean `(component f.fieldset)`? ('site/templates/components/site-group-form.hbs' @ L10:C7) \"],null]],null,{\"statements\":[[0,\"      \"],[7,\"legend\",true],[8],[0,\"Group\"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"form\",\"element\"]],\"expected `f.form.element` to be a contextual component but found a string. Did you mean `(component f.form.element)`? ('site/templates/components/site-group-form.hbs' @ L14:C10) \"],null]],[[\"controlType\",\"label\",\"placeholder\",\"property\",\"class\",\"data-test-name\"],[\"text\",\"Name\",\"Group name\",\"name\",\"col-12 col-xs-12\",true]]],false],[0,\"\\n        \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"form\",\"element\"]],\"expected `f.form.element` to be a contextual component but found a string. Did you mean `(component f.form.element)`? ('site/templates/components/site-group-form.hbs' @ L22:C10) \"],null]],[[\"controlType\",\"label\",\"placeholder\",\"property\",\"class\",\"data-test-comment\"],[\"textarea\",\"Comment\",\"\",\"comment\",\"col-12 col-xs-12\",true]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[23,1,[\"actions\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "site/templates/components/site-group-form.hbs"
    }
  });

  _exports.default = _default;
});