define('ember-ric-utils/components/is-able/component', ['exports', 'ember-ric-utils/components/is-able/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    behavior: Ember.inject.service(),

    route: null,
    possible: true,

    isPermitted: Ember.computed('route', 'behavior.behavior', 'possible', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'route', 'behavior', 'possible'),
          route = _EmberGetProperties.route,
          behavior = _EmberGetProperties.behavior,
          possible = _EmberGetProperties.possible;

      return possible && behavior.isAble(route);
    }).readOnly()

  });
});