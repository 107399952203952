define("ember-yeti-table/components/yeti-table/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7KIs06T7",
    "block": "{\"symbols\":[\"rowData\",\"row\",\"column\",\"rowData\",\"index\",\"&default\"],\"statements\":[[4,\"if\",[[25,6]],null,{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"data\"]]],null,{\"statements\":[[0,\"    \"],[14,6,[[28,\"hash\",null,[[\"row\"],[[28,\"component\",[\"yeti-table/body/row\"],[[\"class\",\"theme\",\"onClick\",\"columns\"],[[24,[\"rowClass\"]],[24,[\"theme\"]],[24,[\"onRowClick\"]],[24,[\"columns\"]]]]]]]],[23,4,[]],[23,5,[]]]],[0,\"\\n\"]],\"parameters\":[4,5]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"data\"]]],null,{\"statements\":[[4,\"yeti-table/body/row\",null,[[\"class\",\"theme\",\"onClick\",\"columns\"],[[24,[\"rowClass\"]],[24,[\"theme\"]],[28,\"action\",[[23,0,[]],[24,[\"onRowClick\"]],[23,1,[]]],null],[24,[\"columns\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"columns\"]]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"cell\"]],\"expected `row.cell` to be a contextual component but found a string. Did you mean `(component row.cell)`? ('ember-yeti-table/components/yeti-table/body/template.hbs' @ L19:C11) \"],null]],[[\"class\"],[[23,3,[\"columnClass\"]]]],{\"statements\":[[4,\"if\",[[23,3,[\"prop\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"get\",[[23,1,[]],[23,3,[\"prop\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[3]},null],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/body/template.hbs"
    }
  });

  _exports.default = _default;
});