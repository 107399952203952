define('ember-string-helpers/helpers/uc-words', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ucWords = ucWords;
  function ucWords(params, hash) {
    var string = String(params[0]),
        force = hash.force === true ? true : false;

    if (!string) {

      return params[0];
    }

    if (force) {
      string = string.toLowerCase();
    }

    return string.replace(/^([a-z\u00E0-\u00FC])|\s+([a-z\u00E0-\u00FC])/g, function ($1) {
      return $1.toUpperCase();
    });
  }

  exports.default = Ember.Helper.helper(ucWords);
});