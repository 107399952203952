define('ember-ric-utils/components/remove-loading/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('.dcr-app-loading').remove();
    }
  });
});