define('ember-contextual-table/components/dt-column-header', ['exports', 'ember-contextual-table/templates/dt-column-header'], function (exports, _dtColumnHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _dtColumnHeader.default,
    tagName: 'th',
    classNames: ['contextual-header-cell']
  });
});