define("ember-bootstrap/components/base/bs-nav/link-to", ["exports", "ember-bootstrap/mixins/component-child"], function (_exports, _componentChild) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   Extended `{{link-to}}` component for use within Navs.
  
   @class NavLinkTo
   @namespace Components
   @extends Ember.LinkComponent
   @uses Mixins.ComponentChild
   @public
   */
  var _default = Ember.LinkComponent.extend(_componentChild.default, {
    init: function init() {
      this._super.apply(this, arguments);

      (false && !(false) && Ember.deprecate('The link-to component yielded by bs-nav is deprecated. Use the route property of the yielded item component instead!\n' + 'Before: {{#nav.item}}{{#nav.link-to "index"}}foo{{/nav.link-to}}{{/nav.item}}\n' + 'After: {{#nav.item route="index"}}foo{{/nav.item}} or: <nav.item @route="index">foo</nav.item>', false, {
        id: 'ember-bootstrap.nav.link-to',
        until: '4.0'
      }));
    }
  });

  _exports.default = _default;
});