define("site/routes/sections/index/menus", ["exports", "site/routes/authorized"], function (_exports, _authorized) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend({
    breadCrumb: null,
    _routeKey: 'menus',
    model: function model(params) {
      return Ember.get(this, 'store').findRecord('site-section', params.section_id);
    }
  });

  _exports.default = _default;
});