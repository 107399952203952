define("ember-bootstrap/mixins/dropdown-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for components that act as dropdown toggles.
   *
   * @class DropdownToggle
   * @namespace Mixins
   * @private
   */
  var _default = Ember.Mixin.create({
    classNames: ['dropdown-toggle'],

    /**
     * @property ariaRole
     * @default button
     * @type string
     * @protected
     */
    ariaRole: 'button',

    /**
     * Reference to the parent dropdown component
     *
     * @property dropdown
     * @type {Components.Dropdown}
     * @private
     */
    dropdown: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var dropdown = this.get('dropdown');

      if (dropdown) {
        Ember.run.schedule('actions', this, function () {
          if (!this.get('isDestroyed')) {
            dropdown.set('toggle', this);
          }
        });
      }
    }
  });

  _exports.default = _default;
});