define("site/templates/components/ldap-user-lookup-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mdz+ArMf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ric-dialog\",null,[[\"onClose\",\"class\"],[[24,[\"onClose\"]],[24,[\"componentCssClassName\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"ldap-user-lookup\",null,[[\"onSelect\",\"lookupOnly\"],[[24,[\"onSelect\"]],false]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "site/templates/components/ldap-user-lookup-dialog.hbs"
    }
  });

  _exports.default = _default;
});