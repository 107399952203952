define("site/components/ldap-user-lookup-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    onClose: null,
    onSelect: null
  });

  _exports.default = _default;
});