define("ember-preferences/storage/namespaceable", ["exports", "ember-preferences/storage/decorator"], function (_exports, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_decorator.default, {
    namespace: null,
    setItem: function setItem(key, value) {
      return this._super(this.fullQualifiedKey(key), value);
    },
    getItem: function getItem(key) {
      return this._super(this.fullQualifiedKey(key));
    },
    removeItem: function removeItem(key) {
      return this._super(this.fullQualifiedKey(key));
    },

    /**
     * @private
     */
    fullQualifiedKey: function fullQualifiedKey(key) {
      var namespace = this.get('namespace');
      var fqk;

      if (typeof namespace !== 'undefined' && namespace !== null) {
        fqk = "".concat(this.get('namespace'), ":").concat(key);
      } else {
        fqk = key;
      }

      return fqk;
    }
  });

  _exports.default = _default;
});