define("site/controllers/users/index/new", ["exports", "site/validations/create-site-user"], function (_exports, _createSiteUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    CreateSiteUserValidation: _createSiteUser.default
  });

  _exports.default = _default;
});