define('ember-ric-utils/components/sortable-th/component', ['exports', 'ember-ric-utils/components/sortable-th/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var noop = function noop() {};

  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'th',
    classNames: ['sortable'],
    classNameBindings: ['order', 'active'],

    context: null,
    onClick: noop,

    order: Ember.computed('context.sortAscending', function () {
      return Ember.getWithDefault(this, 'context.sortAscending', true) ? 'asc' : 'desc';
    }),

    active: Ember.computed('context.sortProperty', 'property', function () {
      return Ember.get(this, 'context.sortProperty') === Ember.get(this, 'property');
    }),

    click: function click() {
      var onClick = Ember.get(this, 'onClick');

      if (typeof onClick === 'function') {
        onClick(Ember.get(this, 'property'));
      }
    }
  });
});