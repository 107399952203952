define('ember-ric-bootstrap/components/ric-checkbox/component', ['exports', 'ember-ric-bootstrap/components/ric-checkbox/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    model: null,
    property: null,
    autosave: false,
    readonly: false,

    isSaving: false,
    isSuccess: false,
    isFailure: false,

    _value: Ember.computed('model', 'property', {
      get: function get() {
        var model = Ember.get(this, 'model');
        var property = Ember.get(this, 'property');

        return Ember.get(model, property);
      },
      set: function set(key, value) {
        var model = Ember.get(this, 'model');
        var property = Ember.get(this, 'property');

        Ember.set(model, property, value);
        this.save();

        return value;
      }
    }),

    save: function save() {
      var _this = this;

      var model = Ember.get(this, 'model');

      this.setProperties({
        isSaving: true,
        isSuccess: false,
        isFailure: false
      });

      return model.save().then(function () {
        _this.setProperties({
          isSaving: false,
          isSuccess: true,
          isFailure: false
        });
      }).catch(function () {
        _this.setProperties({
          isSaving: false,
          isSuccess: false,
          isFailure: true
        });

        model.rollbackAttributes();
      });
    }
  });
});