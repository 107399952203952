define("site/components/site-group-form", ["exports", "ember-ric-utils/mixins/form-base"], function (_exports, _formBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formBase.default, {});

  _exports.default = _default;
});