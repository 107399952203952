define('ember-ric-utils/mixins/masquerading', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ENV: {},

    currentSession: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    _updateSession: function _updateSession(username, token, options) {
      var _this = this;

      var data = { auth: { username: username } };
      var store = Ember.get(this, 'store');

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: _this.ENV.APIEndpoint + '/' + _this.ENV.APINamespace + '/masquerades',
          method: options.method,
          data: JSON.stringify(data),
          dataType: 'json',
          contentType: 'application/json',
          headers: { 'Authorization': 'Bearer ' + token }
        }).then(function (response) {
          Ember.run(function () {
            try {
              var authenticator = Ember.getOwner(_this).lookup('authenticator:knock');
              var sessionData = authenticator.handleAuthResponse(response);

              store.unloadAll();
              _this._resetSession();

              Ember.run.next(function () {
                authenticator.trigger('sessionDataUpdated', sessionData);
                resolve();
              });
            } catch (error) {
              reject(error);
            }
          });
        }, function (xhr) {
          Ember.run(function () {
            reject(xhr.responseJSON || xhr.responseText);
          });
        });
      });
    },
    _resetSession: function _resetSession() {},


    actions: {
      startMasquerading: function startMasquerading(user) {
        var _this2 = this;

        var username = Ember.get(user, 'userId');
        var token = Ember.get(this, 'currentSession.token');

        this._updateSession(username, token, { method: 'POST' }).then(function () {
          Ember.get(_this2, 'flashMessages').success('Now masquerading');
          _this2.transitionTo('index');
        }).catch(function () {
          Ember.get(_this2, 'flashMessages').error('Failed to masquerade');
        });
      },
      stopMasquerading: function stopMasquerading() {
        var _this3 = this;

        var username = Ember.get(this, 'currentSession.adminUsername');
        var token = Ember.get(this, 'currentSession.token');

        this._updateSession(username, token, { method: 'DELETE' }).then(function () {
          Ember.get(_this3, 'flashMessages').success('Stopped masquerading');
          _this3.transitionTo('index');
        }).catch(function () {
          Ember.get(_this3, 'flashMessages').error('Failed to stop masquerading');
        });
      }
    }

  });
});