define("ember-ric-bootstrap/components/ric-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "je+ZgVgg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"readonly\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[28,\"if\",[[24,[\"_value\"]],\"check-\"],null],\"square-o\"]]],[8],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[28,\"mut\",[[24,[\"_value\"]]],null]]]],false],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-spinner       \",[28,\"if\",[[28,\"not\",[[24,[\"isSaving\"]]],null],\"hidden\"],null]]]],[8],[9],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-check success \",[28,\"if\",[[28,\"not\",[[24,[\"isSuccess\"]]],null],\"hidden\"],null]]]],[8],[9],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"fa fa-times error   \",[28,\"if\",[[28,\"not\",[[24,[\"isFailure\"]]],null],\"hidden\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-bootstrap/components/ric-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});