define('ember-string-helpers/helpers/substr', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.substr = substr;

	function _toArray(arr) {
		return Array.isArray(arr) ? arr : Array.from(arr);
	}

	// eslint-disable-next-line no-unused-vars
	function substr(_ref, hash) {
		var _ref2 = _toArray(_ref),
		    value = _ref2[0],
		    rest = _ref2.slice(1);

		if (typeof value === 'string') {
			var start = hash.start || 0;
			var length = hash.length;
			return value.substr(start, length);
		} else {
			return value;
		}
	}

	exports.default = Ember.Helper.helper(substr);
});