define("ember-route-task-helper/helpers/route-task", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray", "ember-route-task-helper/utils/route-task"], function (_exports, _toConsumableArray2, _toArray2, _routeTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    router: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = (0, _toArray2.default)(_ref),
          taskName = _ref2[0],
          params = _ref2.slice(1);

      return _routeTask.routeTaskFromRouterService.apply(void 0, [Ember.get(this, 'router'), taskName].concat((0, _toConsumableArray2.default)(params)));
    }
  });

  _exports.default = _default;
});