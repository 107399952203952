define('ember-ric-bootstrap/components/ric-filter/component', ['exports', 'ember-keyboard', 'ember-ric-bootstrap/components/ric-filter/template'], function (exports, _emberKeyboard, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    layout: _template.default,
    classNames: ['filter'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('keyboardActivated', true);
    },


    abort: Ember.on((0, _emberKeyboard.keyUp)('Escape'), function () {
      this.reset();
    }),

    reset: function reset() {
      var action = this.get('on-reset');

      this.set('search', null);

      if (action) {
        action();
      }
    },


    actions: {
      submit: function submit() {
        var action = this.get('on-submit');
        var search = this.get('search');

        if (action) {
          action({ search: search });
        }
      },
      reset: function reset() {
        this.reset();
      },
      updateSearch: function updateSearch(search) {
        var action = this.get('on-update');

        this.set('search', search);

        if (action) {
          action({ search: search });
        }
      }
    }
  });
});