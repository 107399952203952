define('ember-contextual-table/components/dt-sortable-column-base', ['exports', 'ember-contextual-table/templates/dt-sortable-column-base'], function (exports, _dtSortableColumnBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    layout: _dtSortableColumnBase.default,
    upArrowVisible: computed('isAscending', function () {
      return this.get('isAscending') === false;
    }),
    downArrowVisible: computed('isAscending', function () {
      return this.get('isAscending') === true;
    }),

    fireSortInformationUpdatedEvent: function fireSortInformationUpdatedEvent(isAscending) {
      var actionHandler = this.get('sortinformationupdated') || function () {};
      actionHandler(this.get('propertyName'), isAscending);
    },


    actions: {
      onsortinfoupdate: function onsortinfoupdate(isAscending) {
        this.fireSortInformationUpdatedEvent(isAscending);
      },

      onclick: function onclick() {
        if (this.get('isAscending') === true) {
          this.set('isAscending', false);
        } else if (this.get('isAscending') === undefined) {
          this.set('isAscending', true);
        } else {
          this.set('isAscending', undefined);
        }

        this.fireSortInformationUpdatedEvent(this.get('isAscending'));
      }
    }
  });
});