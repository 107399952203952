define('ember-contextual-table/components/empty-component', ['exports', 'ember-contextual-table/templates/empty-component'], function (exports, _emptyComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'span',
    isVisible: false,
    layout: _emptyComponent.default
  });
});