define("site/components/site-setting-form", ["exports", "ember-ric-utils/mixins/form-base"], function (_exports, _formBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TYPE_OPTIONS = ['String', 'Integer'];

  var _default = Ember.Component.extend(_formBase.default, {
    TYPE_OPTIONS: TYPE_OPTIONS
  });

  _exports.default = _default;
});