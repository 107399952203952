define("ember-contextual-table/templates/pager-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R42MJf3x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"nav\",true],[8],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"pager\"],[8],[0,\"\\n        \"],[7,\"li\",true],[11,\"class\",[22,\"previousButtonClass\"]],[8],[7,\"a\",true],[10,\"href\",\"#\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"previous\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"                \"],[14,1,[[28,\"hash\",null,[[\"previous\"],[true]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[22,\"previousLabel\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"currentPage\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\",true],[10,\"class\",\"disabled\"],[8],[7,\"a\",true],[10,\"href\",\"javascript:void(0)\"],[8],[1,[22,\"currentPage\"],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"li\",true],[11,\"class\",[22,\"nextButtonClass\"]],[8],[7,\"a\",true],[10,\"href\",\"#\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"next\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"                \"],[14,1,[[28,\"hash\",null,[[\"next\"],[true]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[22,\"nextLabel\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-contextual-table/templates/pager-component.hbs"
    }
  });

  _exports.default = _default;
});