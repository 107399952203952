define("site/routes/users/index/new", ["exports", "site/routes/authorized", "ember-ric-utils/mixins/crud-methods"], function (_exports, _authorized, _crudMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend(_crudMethods.default, {
    breadCrumb: null,
    currentOrganization: Ember.inject.service(),
    successMessage: 'User created',
    failureMessage: 'Failed to create user',
    redirectUrl: 'users',
    _model: function _model() {
      return this._modelForRoute().user;
    },
    model: function model() {
      var currentOrganization = Ember.get(this, 'currentOrganization');
      var filter;

      if (currentOrganization) {
        var organization_id = currentOrganization.get('organization.id');
        filter = {
          organization_id: organization_id
        };
      }

      var user = Ember.get(this, 'store').createRecord('site-user');
      var groups = Ember.get(this, 'store').query('site-group', {
        filter: filter
      });
      return Ember.RSVP.hash({
        user: user,
        groups: groups
      });
    }
  });

  _exports.default = _default;
});