define("ember-bootstrap/mixins/transition-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for components that support transitions
   *
   * @class TransitionSupport
   * @namespace Mixins
   * @private
   */
  var _default = Ember.Mixin.create({
    /**
     * @property transitionsEnabled
     * @type boolean
     * @private
     */
    transitionsEnabled: Ember.computed.reads('fade'),

    /**
     * Access to the fastboot service if available
     *
     * @property fastboot
     * @type {Ember.Service}
     * @private
     */
    fastboot: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      return owner.lookup('service:fastboot');
    }),

    /**
     * Use CSS transitions?
     *
     * @property usesTransition
     * @type boolean
     * @readonly
     * @private
     */
    usesTransition: Ember.computed('fade', 'fastboot.isFastBoot', function () {
      return !this.get('fastboot.isFastBoot') && this.get('transitionsEnabled');
    })
  });

  _exports.default = _default;
});