define("ember-yeti-table/-private/decorators/default-if-undefined", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/objectWithoutProperties", "@ember-decorators/utils/decorator"], function (_exports, _objectSpread2, _objectWithoutProperties2, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALUES = new WeakMap();

  function getValues(object) {
    if (!VALUES.has(object)) {
      VALUES.set(object, Object.create(null));
    }

    return VALUES.get(object);
  }

  var _default = (0, _decorator.decorator)(function (_ref) {
    var initializer = _ref.initializer,
        key = _ref.key,
        descriptor = _ref.descriptor,
        desc = (0, _objectWithoutProperties2.default)(_ref, ["initializer", "key", "descriptor"]);
    delete descriptor.writable;
    return (0, _objectSpread2.default)({}, desc, {
      key: key,
      kind: 'method',
      descriptor: (0, _objectSpread2.default)({}, descriptor, {
        get: function get() {
          var values = getValues(this);

          if (!(key in values)) {
            values[key] = initializer.call(this);
          }

          return values[key];
        },
        set: function set(value) {
          getValues(this)[key] = value === undefined ? initializer.call(this) : value;
        }
      })
    });
  });

  _exports.default = _default;
});