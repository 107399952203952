define("site/routes/sections/index/menus/new", ["exports", "site/routes/authorized", "ember-ric-utils/mixins/crud-methods"], function (_exports, _authorized, _crudMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend(_crudMethods.default, {
    breadCrumb: null,
    successMessage: 'Menu created',
    failureMessage: 'Failed to create menu',
    redirectUrl: 'sections',
    _routeKey: 'menus.new',
    _beforeCreate: function _beforeCreate(changeset) {
      var section = this.modelFor('sections.index.menus');
      changeset.set('section', section);
    },
    model: function model() {
      return Ember.get(this, 'store').createRecord('site-menu');
    }
  });

  _exports.default = _default;
});