define("site/controllers/groups/show/members/new", ["exports", "site/validations/create-site-group-membership"], function (_exports, _createSiteGroupMembership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    CreateSiteGroupMembershipValidation: _createSiteGroupMembership.default,
    actions: {
      updateUser: function updateUser(changeset, user) {
        changeset.set('user', user);
      }
    }
  });

  _exports.default = _default;
});