define('ember-string-helpers/helpers/regexp-replace', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.regexpReplace = regexpReplace;
  function regexpReplace(params, hash) {
    var string = params[0],
        regex = params[1].replace(/^(\/)(.*)+(\/)$/, '$2'),
        flags = hash.flags,
        regexPattern = new RegExp(regex, flags),
        replacePattern = params[2];

    return string.replace(regexPattern, replacePattern);
  }

  exports.default = Ember.Helper.helper(regexpReplace);
});