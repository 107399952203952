define("ember-ric-utils/components/ric-resources/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qIObxadD",
    "block": "{\"symbols\":[\"resource\",\"@allowEdit\"],\"statements\":[[4,\"if\",[[24,[\"resources\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"resources\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"presentable\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[8],[0,\"\\n            \"],[7,\"img\",true],[11,\"src\",[23,1,[\"url\"]]],[10,\"alt\",\"\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"a\",true],[11,\"href\",[23,1,[\"url\"]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,2,[]],[23,1,[\"permissions\",\"delete\"]]],null]],null,{\"statements\":[[0,\"          \"],[7,\"button\",false],[12,\"class\",\"btn btn-sm btn-danger\"],[3,\"action\",[[23,0,[]],[28,\"toggle\",[\"showConfirmation\",[23,0,[]]],null]]],[8],[0,\"\\n            \"],[7,\"i\",true],[10,\"class\",\"fa fa-trash\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"showConfirmation\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"ric-delete-dialog\",null,[[\"subject\",\"onCancel\",\"onSubmit\"],[\"Document\",[28,\"action\",[[23,0,[]],[28,\"toggle\",[\"showConfirmation\",[23,0,[]]],null]],null],[28,\"action\",[[23,0,[]],\"delete\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"showEmpty\"]]],null,{\"statements\":[[0,\"  \"],[7,\"em\",true],[8],[0,\"No resources\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-utils/components/ric-resources/template.hbs"
    }
  });

  _exports.default = _default;
});