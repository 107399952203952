define('ember-ric-utils/mixins/sort-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    sortProperty: 'id',
    sortAscending: true,

    sortOrder: Ember.computed('sortProperty', 'sortAscending', function () {
      return [this.get('sortProperty') + ':' + (this.get('sortAscending') ? 'asc' : 'desc')];
    }),

    actions: {
      sortBy: function sortBy(key) {
        this.toggleProperty('sortAscending');
        this.set('sortProperty', key);
      }
    }
  });
});