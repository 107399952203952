define('ember-ric-bootstrap/components/ric-dialog/component', ['exports', 'ember-ric-bootstrap/components/ric-dialog/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    onClose: null,

    class: Ember.computed('classNames', function () {
      var classNames = this.get('classNames') || [];
      return classNames.join(' ');
    })
  });
});