define("site/models/site-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    groups: hasMany('site-group'),
    firstName: attr('string'),
    lastName: attr('string'),
    userId: attr('string'),
    email: attr('string'),
    phone: attr('string'),
    department: attr('string'),
    institution: attr('string'),
    title: attr('string'),
    position: attr('string'),
    status: attr('string'),
    active: attr('number'),
    preferences: attr(),
    groupIds: attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    newPassword: attr('string'),
    newPasswordConfirmation: attr('string'),
    resetPassword: attr('number'),
    name: Ember.computed('firstName', 'lastName', function () {
      return "".concat(Ember.get(this, 'lastName'), ", ").concat(Ember.get(this, 'firstName'));
    })
  });

  _exports.default = _default;
});