define("site/controllers/groups/index", ["exports", "ember-ric-utils/mixins/search-term", "ember-ric-utils/mixins/load-data"], function (_exports, _searchTerm, _loadData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_loadData.default, _searchTerm.default, {});

  _exports.default = _default;
});