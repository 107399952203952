define("ember-yeti-table/components/yeti-table/pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L+W23kU4",
    "block": "{\"symbols\":[\"pageSize\"],\"statements\":[[4,\"if\",[[24,[\"showInfo\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[24,[\"theme\",\"pagination\",\"info\"]]],[8],[0,\"\\n    Showing \"],[1,[24,[\"paginationData\",\"pageStart\"]],false],[0,\" to \"],[1,[24,[\"paginationData\",\"pageEnd\"]],false],[0,\" of \"],[1,[24,[\"paginationData\",\"totalRows\"]],false],[0,\" entries\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showPageSizeSelector\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[24,[\"theme\",\"pagination\",\"pageSize\"]]],[8],[0,\"\\n    Rows per page:\\n    \"],[7,\"select\",true],[11,\"disabled\",[22,\"disabled\"]],[11,\"onchange\",[28,\"action\",[[23,0,[]],[24,[\"paginationActions\",\"changePageSize\"]]],[[\"value\"],[\"target.value\"]]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"pageSizes\"]]],null,{\"statements\":[[0,\"        \"],[7,\"option\",true],[11,\"value\",[23,1,[]]],[11,\"selected\",[28,\"yeti-table-eq\",[[24,[\"paginationData\",\"pageSize\"]],[23,1,[]]],null]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showButtons\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",true],[11,\"class\",[24,[\"theme\",\"pagination\",\"previous\"]]],[11,\"disabled\",[22,\"shouldDisablePrevious\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"paginationActions\",\"previousPage\"]]],null]],[8],[0,\"\\n    Previous\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"button\",true],[11,\"class\",[24,[\"theme\",\"pagination\",\"next\"]]],[11,\"disabled\",[22,\"shouldDisableNext\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"paginationActions\",\"nextPage\"]]],null]],[8],[0,\"\\n    Next\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/pagination/template.hbs"
    }
  });

  _exports.default = _default;
});