define("site/routes/sections/index", ["exports", "site/routes/authorized", "ember-ric-utils/mixins/filtered-records"], function (_exports, _authorized, _filteredRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend(_filteredRecords.default, {
    breadCrumb: null,
    model: function model() {
      return Ember.get(this, 'store').findAll('site-section', {
        include: 'menus'
      });
    },
    reload: function reload() {}
  });

  _exports.default = _default;
});