define("ember-yeti-table/components/yeti-table/foot/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MGvC785C",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"tr\",false],[12,\"class\",[29,[[22,\"class\"],\" \",[24,[\"theme\",\"tfootRow\"]],\" \",[24,[\"theme\",\"row\"]]]]],[13,1],[8],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"cell\"],[[28,\"component\",[\"yeti-table/foot/row/cell\"],[[\"theme\",\"parent\"],[[24,[\"theme\"]],[23,0,[]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/foot/row/template.hbs"
    }
  });

  _exports.default = _default;
});