define("site/controllers/groups/show/permissions/new", ["exports", "site/validations/create-site-permission"], function (_exports, _createSitePermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    CreateSitePermissionValidation: _createSitePermission.default,
    actions: {
      updateMenu: function updateMenu(changeset, menu) {
        changeset.set('menu', menu);
      }
    }
  });

  _exports.default = _default;
});