define("site/routes/groups/show/members", ["exports", "site/routes/authorized", "ember-ric-utils/mixins/filtered-records"], function (_exports, _authorized, _filteredRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend(_filteredRecords.default, {
    _routeKey: 'group-memberships',
    model: function model() {
      var group = this.modelFor('groups.show');
      var groupMemberships = group.get('groupMemberships');
      return Ember.RSVP.hash({
        group: group,
        groupMemberships: groupMemberships
      });
    }
  });

  _exports.default = _default;
});