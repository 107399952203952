define("ember-preferences/storage/defaultable", ["exports", "ember-preferences/storage/decorator"], function (_exports, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_decorator.default, {
    getItem: function getItem(key) {
      return this.calculateValue(key, this._super(key));
    },
    calculateValue: function calculateValue(key, value) {
      if (value === undefined || value === null) {
        value = this.get('defaults')[key];

        if (typeof value === 'function') {
          value = value();
        }
      }

      return value;
    }
  });

  _exports.default = _default;
});