define("ember-yeti-table/components/yeti-table/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rpyQBqMu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"tr\",true],[11,\"class\",[29,[[22,\"trClass\"],\" \",[24,[\"theme\",\"theadRow\"]],\" \",[24,[\"theme\",\"row\"]]]]],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"column\"],[[28,\"component\",[\"yeti-table/head/row/column\"],[[\"sortable\",\"sortSequence\",\"onClick\",\"parent\",\"theme\"],[[24,[\"sortable\"]],[24,[\"sortSequence\"]],[28,\"action\",[[23,0,[]],\"onColumnClick\"],null],[24,[\"parent\"]],[24,[\"theme\"]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/header/template.hbs"
    }
  });

  _exports.default = _default;
});