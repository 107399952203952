define("site/routes/groups/show/members/delete", ["exports", "site/routes/authorized", "ember-ric-utils/mixins/crud-methods"], function (_exports, _authorized, _crudMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend(_crudMethods.default, {
    breadCrumb: null,
    successMessage: 'Member deleted',
    failureMessage: 'Failed to delete member',
    redirectUrl: 'groups.show.members',
    _routeKey: 'group-memberships.delete',
    model: function model(params) {
      return Ember.get(this, 'store').findRecord('site-group-membership', params.group_membership_id);
    }
  });

  _exports.default = _default;
});