define("site/engine", ["exports", "ember-engines/engine", "ember-resolver", "ember-load-initializers", "site/config/environment"], function (_exports, _engine, _emberResolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var modulePrefix = _environment.default.modulePrefix;

  var Eng = _engine.default.extend({
    modulePrefix: modulePrefix,
    Resolver: _emberResolver.default,
    dependencies: {
      services: ['behavior', 'flash-messages', 'store', 'session', 'router', 'settings', 'current-organization'],
      externalRoutes: ['application', 'login', 'logout']
    }
  });

  (0, _emberLoadInitializers.default)(Eng, modulePrefix);
  var _default = Eng;
  _exports.default = _default;
});