define("site/routes/groups/show/permissions/delete", ["exports", "site/routes/authorized", "ember-ric-utils/mixins/crud-methods"], function (_exports, _authorized, _crudMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend(_crudMethods.default, {
    breadCrumb: null,
    flashMessages: Ember.inject.service(),
    successMessage: 'Permission deleted',
    failureMessage: 'Failed to delete permission',
    redirectUrl: 'groups.show.permissions',
    _routeKey: 'permissions.delete',
    _model: function _model() {
      return this._modelForRoute();
    },
    model: function model(params) {
      return Ember.get(this, 'store').findRecord('site-permission', params.permission_id);
    }
  });

  _exports.default = _default;
});