define('ember-links-with-follower/configuration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getWithDefault = Ember.getWithDefault,
      testing = Ember.testing,
      typeOf = Ember.typeOf;


  var DEFAULTS = {
    followerAnimationDuration: testing ? 0 : 150
  };

  exports.default = {
    followerAnimationDuration: DEFAULTS.followerAnimationDuration,

    load: function load(config) {
      for (var property in this) {
        if (this._hasProperty(property)) {
          this[property] = getWithDefault(config, property, DEFAULTS[property]);
        }
      }
    },
    _hasProperty: function _hasProperty(prop) {
      return this.hasOwnProperty(prop) && typeOf(this[prop]) !== 'function';
    }
  };
});