define("site/routes/sections/index/menus/delete", ["exports", "site/routes/authorized", "ember-ric-utils/mixins/crud-methods"], function (_exports, _authorized, _crudMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend(_crudMethods.default, {
    breadCrumb: null,
    successMessage: 'Menu deleted',
    failureMessage: 'Failed to delete menu',
    redirectUrl: 'sections',
    _routeKey: 'menus.delete',
    model: function model(params) {
      return Ember.get(this, 'store').findRecord('site-menu', params.menu_id);
    }
  });

  _exports.default = _default;
});