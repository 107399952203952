define("ember-yeti-table/components/yeti-table/foot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PjPs7DTc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"row\"],[[28,\"component\",[\"yeti-table/foot/row\"],[[\"columns\",\"theme\",\"parent\"],[[24,[\"columns\"]],[24,[\"theme\"]],[24,[\"parent\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/foot/template.hbs"
    }
  });

  _exports.default = _default;
});