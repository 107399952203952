define('ember-ric-utils/mixins/load-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PAGE_SIZE = 15;

  exports.default = Ember.Mixin.create({

    flashMessages: Ember.inject.service(),

    pageSize: PAGE_SIZE,

    totalPages: Ember.computed.alias('_records.meta.page-count'),
    totalRows: Ember.computed('{totalPages,pageSize}', function () {
      return this.totalPages * this.pageSize;
    }),

    loadDataTask: Ember.computed.alias('model.loadDataTask'),
    isLoading: Ember.computed.alias('loadDataTask.isRunning'),

    _records: Ember.computed.alias('loadDataTask.lastSuccessful.value'),
    _recordsProxy: null,

    _updateRecordsProxy: Ember.observer('_records', function () {
      if (this._recordsProxy && this._records) {
        this._recordsProxy.set('content', this._records);
      }
    }),

    actions: {
      loadData: function loadData(params) {
        var _this = this;

        var task = this.loadDataTask;
        (false && !(task) && Ember.assert('Missing required `this.loadDataTask`', task));


        var records = this._records || [];
        var proxy = this._recordsProxy || Ember.ArrayProxy.create({ content: records });

        this.set('_recordsProxy', proxy);

        if (params.paginationData) {
          this.set('page', params.paginationData.pageNumber);
          this.set('pageSize', params.paginationData.pageSize);
        }

        task.perform(params).then(function (data) {
          _this.set('meta', data.meta);
          return data;
        }).catch(function (error) {
          if (error.name === "TaskCancelation") {
            return;
          }

          // eslint-disable-next-line
          console.error(error);
          _this.flashMessages.error("Failed to load records");
        });

        return proxy;
      }
    }

  });
});