define('ember-ric-bootstrap/components/ric-delete-dialog/component', ['exports', 'ember-ric-bootstrap/components/ric-delete-dialog/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    subject: 'Entry',
    operation: 'Delete',

    model: null,

    onSubmit: null,
    onCancel: null

  });
});