define('ember-contextual-table/components/dt-selection-column', ['exports', 'ember-contextual-table/templates/dt-selection-column'], function (exports, _dtSelectionColumn) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _dtSelectionColumn.default,
    tagName: 'td',
    classNames: ['contextual-selection-cell'],

    actions: {
      change: function change(checked) {
        var actionName = checked ? 'rowSelected' : 'rowDeselected';
        var action = this.get(actionName) || function () {};
        action();
      }
    }
  });
});