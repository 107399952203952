define("site/controllers/sections/index", ["exports", "ember-ric-utils/mixins/search-term", "ember-ric-utils/mixins/filtered-records"], function (_exports, _searchTerm, _filteredRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_searchTerm.default, _filteredRecords.default, {
    filterOn: 'name',
    sections: Ember.computed.alias('model'),
    sortedRecords: Ember.computed.alias('sections'),
    columns: [{
      label: 'Priority',
      valueProperty: 'priority'
    }, {
      label: 'Block',
      valueProperty: 'block'
    }, {
      label: 'Name',
      valueProperty: 'name'
    }, {
      label: 'URL',
      valueProperty: 'url'
    }, {
      label: 'Protected',
      valueProperty: 'protected'
    }, {
      label: 'Visible',
      valueProperty: 'visible'
    }, {
      label: 'Reference',
      valueProperty: 'reference'
    }, {
      label: 'Group',
      valueProperty: 'groupName'
    }, {
      label: '',
      class: 'hover-action'
    }]
  });

  _exports.default = _default;
});