define("site/templates/sections/index/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tof7LPtI",
    "block": "{\"symbols\":[\"model\"],\"statements\":[[4,\"with\",[[28,\"changeset\",[[24,[\"model\"]],[24,[\"CreateSiteSectionValidation\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"site-section-form\",null,[[\"changeset\",\"onSubmit\",\"onCancel\"],[[23,1,[]],[28,\"route-action\",[\"create\"],null],[28,\"route-action\",[\"cancel\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "site/templates/sections/index/new.hbs"
    }
  });

  _exports.default = _default;
});