define("ember-yeti-table/components/yeti-table/body/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kVXZRrxw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"cell\"],[[28,\"component\",[\"yeti-table/body/row/cell\"],[[\"theme\",\"parent\"],[[24,[\"theme\"]],[23,0,[]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/body/row/template.hbs"
    }
  });

  _exports.default = _default;
});