define("ember-bootstrap/components/base/bs-form/element/control/textarea", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-bootstrap/components/base/bs-form/element/control", "ember-bootstrap/components/base/bs-form/element/control/input"], function (_exports, _toConsumableArray2, _control, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   @class FormElementControlTextarea
   @namespace Components
   @extends Components.FormElementControl
   @private
   */
  var _default = _control.default.extend({
    attributeBindings: [].concat((0, _toConsumableArray2.default)(_input.baseAttributes), ['value', 'placeholder', 'minlength', 'maxlength', 'autocomplete', 'autocapitalize', 'autocorrect', 'spellcheck', 'rows', 'cols', 'wrap']),
    tagName: 'textarea',
    classNames: ['form-control'],
    change: function change(event) {
      this.get('onChange')(event.target.value);
    },
    input: function input(event) {
      this.get('onChange')(event.target.value);
    }
  });

  _exports.default = _default;
});