define("ember-bootstrap/mixins/control-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for control components to add standard HTML attributes
   *
   * @class ControlAttributes
   * @namespace Mixins
   * @private
   */
  var _default = Ember.Mixin.create({
    attributeBindings: ['name', 'autofocus', 'disabled', 'readonly', 'required', 'tabindex', 'form', 'title', 'ariaDescribedBy:aria-describedby'],
    tagName: 'input'
  });

  _exports.default = _default;
});