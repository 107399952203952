define("ember-string-helpers/helpers/format-phone", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatPhone = formatPhone;
  function formatPhone(params /*, hash*/) {
    var phone = params[0];

    if (!phone) {
      // eslint-disable-next-line no-console
      console.warn("%c{{format-phone}} Invalid phone number passed: %s", "color: orange;", // http://www.w3schools.com/html/html_colornames.asp
      phone);
      return phone;
    }

    return String(phone).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  exports.default = Ember.Helper.helper(formatPhone);
});