define('ember-contextual-table/components/dt-selection-column-footer', ['exports', 'ember-contextual-table/templates/dt-selection-column-footer'], function (exports, _dtSelectionColumnFooter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _dtSelectionColumnFooter.default,
    tagName: 'td',
    classNames: ['contextual-selection-footer-cell'],

    actions: {
      change: function change(checked) {
        var actionName = checked ? 'selected' : 'deselected';
        var action = this.get(actionName) || function () {};
        action();
      }
    }
  });
});