define('ember-string-helpers/helpers/lower-case', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.lowerCase = lowerCase;
  function lowerCase(params) {
    return params[0].toLowerCase();
  }

  exports.default = Ember.Helper.helper(lowerCase);
});