define("site/validations/create-site-user", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: [(0, _validators.validatePresence)({
      presence: true
    }), (0, _validators.validateLength)({
      max: 255
    })],
    lastName: [(0, _validators.validatePresence)({
      presence: true
    }), (0, _validators.validateLength)({
      max: 255
    })],
    status: [(0, _validators.validatePresence)({
      presence: true
    }), (0, _validators.validateLength)({
      max: 16
    })],
    userId: [(0, _validators.validatePresence)({
      presence: true
    }), (0, _validators.validateLength)({
      max: 255
    })]
  };
  _exports.default = _default;
});