define("ember-ric-bootstrap/components/ric-form-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vBJi7kJo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"submitDisabled\",\"cancelDisabled\",\"onCancel\"],[[24,[\"submitDisabled\"]],[24,[\"cancelDisabled\"]],[24,[\"onCancel\"]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"bs-button\",null,[[\"defaultText\",\"type\",\"buttonType\",\"disabled\",\"data-test-submit\"],[[24,[\"submitButtonLabel\"]],[24,[\"submitButtonType\"]],\"submit\",[24,[\"submitDisabled\"]],true]]],false],[0,\"\\n  \"],[1,[28,\"bs-button\",null,[[\"defaultText\",\"type\",\"onClick\",\"disabled\",\"data-test-cancel\"],[\"Cancel\",\"outline-secondary\",[24,[\"onCancel\"]],[24,[\"cancelDisabled\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-bootstrap/components/ric-form-actions/template.hbs"
    }
  });

  _exports.default = _default;
});