define("site/routes/groups/show/permissions/new", ["exports", "site/routes/authorized", "ember-ric-utils/mixins/crud-methods"], function (_exports, _authorized, _crudMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorized.default.extend(_crudMethods.default, {
    breadCrumb: null,
    flashMessages: Ember.inject.service(),
    successMessage: 'Permission created',
    failureMessage: 'Failed to create permission',
    redirectUrl: 'groups.show.permissions',
    _routeKey: 'permissions.new',
    _model: function _model() {
      return this._modelForRoute().permission;
    },
    _beforeCreate: function _beforeCreate(changeset) {
      var group = this.modelFor('groups.show');
      changeset.set('group', group);
    },
    model: function model() {
      var permission = Ember.get(this, 'store').createRecord('site-permission');
      var menus = Ember.get(this, 'store').findAll('site-menu', {
        include: 'section'
      });
      return Ember.RSVP.hash({
        permission: permission,
        menus: menus
      });
    }
  });

  _exports.default = _default;
});