define("ember-bootstrap/mixins/type-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin to set the appropriate class for components with differently styled types ("success", "danger" etc.)
   *
   * @class TypeClass
   * @namespace Mixins
   * @private
   */
  var _default = Ember.Mixin.create({
    /**
     * Prefix for the type class, e.g. "btn" for button type classes ("btn-primary2 etc.)
     *
     * @property classTypePrefix
     * @type string
     * @required
     * @protected
     */
    classTypePrefix: null,
    classNameBindings: ['typeClass'],
    typeClass: Ember.computed('type', function () {
      var prefix = this.get('classTypePrefix');
      var type = this.get('type') || 'default';

      if (this.get('outline')) {
        return "".concat(prefix, "-outline-").concat(type);
      }

      return "".concat(prefix, "-").concat(type);
    }),

    /**
     * Property for type styling
     *
     * For the available types see the [Bootstrap docs](http://getbootstrap.com/css/#buttons-options) (use without "btn-" prefix)
     *
     * @property type
     * @type String
     * @default 'default'
     * @public
     */
    type: 'default'
  });

  _exports.default = _default;
});