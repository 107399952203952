define("ember-ric-bootstrap/components/ric-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PCk3pNjx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"form\",false],[12,\"class\",\"form-inline\"],[3,\"action\",[[23,0,[]],\"submit\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group search\"],[8],[0,\"\\n    \"],[7,\"input\",true],[11,\"value\",[22,\"search\"]],[11,\"onInput\",[28,\"action\",[[23,0,[]],\"updateSearch\"],[[\"value\"],[\"target.value\"]]]],[10,\"placeholder\",\"Search...\"],[10,\"class\",\"form-control search-field\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[8],[0,\"Search\"],[9],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-outline-secondary\"],[3,\"action\",[[23,0,[]],\"reset\"]],[8],[0,\"Reset\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-ric-bootstrap/components/ric-filter/template.hbs"
    }
  });

  _exports.default = _default;
});